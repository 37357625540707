export class ApiEndpoints {
    // #PROD Mode
    public static readonly backendHost = "https://www.ktcvinabearings.com/api";
    public static readonly backendHostImage = "https://www.ktcvinabearings.com";

    // #DEV Mode
    // public static readonly backendHost = "http://127.0.0.1:8000/api";
    // public static readonly backendHostImage = "http://127.0.0.1:8000";

    // File
    public static readonly publicFilePath = "/static"

    // Article
    public static readonly article = "/Article";
    public static readonly articleByPath = "/Article/Path";
    public static readonly allArticles = "/Article/GetArticles";
    public static readonly allServices = "/Article/GetServices";
    public static readonly allPosts = "/Article/GetPosts";
    public static readonly allSupportArticles = "/Article/GetSupportArticles";
    public static readonly specificArticle = "/Article/{id}";

    // OuterProduct
    public static readonly outerProduct = "/OuterProduct";
    public static readonly allOuterProduct = "/OuterProduct/GetOuterProducts";
    public static readonly allOuterProductMaintain = "/OuterProduct/GetOuterProductsMaintain";
    public static readonly outerProductByPath = "/OuterProduct/GetByPath";
    public static readonly innerProductByPath = "/InnerProduct/GetByPath";
    public static readonly innerProductById = "/InnerProduct";
    public static readonly specificOuterProduct = "/OuterProduct/{id}";

    // InnerProduct
    public static readonly innerProduct = "/InnerProduct";
    public static readonly innerProductSearchByName = "/InnerProduct/SearchInnerProductByName";
    public static readonly innerProductOutstanding = "/InnerProduct/GetInnerProductOutstanding";

    // Config
    public static readonly configuration = "/Configuration";

    // Banner
    public static readonly banner = "/bannerImages";

    // Subscription
    public static readonly subscription = "/subscriptions";

    // Videos
    public static readonly video = '/videos';
}