import Subscription from "lib/models/subscription";
import { SubscriptionService } from "lib/services/subscription-service";
import { useEffect, useState } from "react";
import AdminNavbar from "../navbar";
import { RenderValueService } from "lib/services/render-value-service";

export default function AdminSubscriptionsPage(){
    const [data, setData] = useState<Subscription[]>([]);

    useEffect(() => {
        SubscriptionService.getAll().then(res => setData(res));
    }, [])

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <h1>Email đã đăng kí</h1>
                </div>

                {/* Table */}
                <table className="min-w-full border border-neutral-200 text-center text-md font-light text-surface bg-white">
                    <thead className="border-b border-neutral-200 font-medium">
                        <tr>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                #
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Email
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Đăng kí lúc
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.map(inner => (
                        <tr className="border-b border-neutral-200" key={inner.id}>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {inner.id}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {inner.emailValue}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {RenderValueService.RenderDateTime(inner.createdAt)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
}