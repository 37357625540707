import AppImageFixed from "components/image-fixed"
import { Button } from "components/ui/button"
import { ImageMetadata } from "lib/types/image-meta"
import { Link } from "react-router-dom"

export default function ServicesByTypeBox(props: {
  header: string,
  description: string,
  image: ImageMetadata,
  routerLink: string
}) {
  return (
    <div className="p-3 rounded-xl flex items-center gap-6 bg-white mt-[10px] mb-[20px]">
      <div className={`overflow-hidden relative rounded-[10px] w-[360px] h-[200px]`}>
        <AppImageFixed image={props.image} />
      </div>

      {/* Content */}
      <div className="pr-[50px] h-[200px] w-[800px] flex flex-col justify-around">
        <div>
          <p className="text-primary font-medium text-[25px]">{props.header}</p>
          <p className="text-neutral-7 text-[16px] leading-6 my-3 font-roboto line-clamp-3 overflow-hidden w-full">{props.description}</p>
        </div>
        <Link to={props.routerLink}>
          <Button variant="outline" className="text-neutral-8 hover:text-primary hover:border-primary">Xem chi tiết</Button>
        </Link>
      </div>
    </div>
  )
}