import AppImage from "components/image-container"
import { ImageMetadata } from "lib/types/image-meta"
import { Link } from "react-router-dom"

export default function ServiceTypeInformation(props: {
  description: string,
  image: ImageMetadata,
  videos?: string
}) {
  return (
    <div className="w-full flex gap-2">

      {/* Left column with 7 cols */}
      <div className="w-7/12 bg-white rounded-xl p-[16px]">
        <p className="body-text-2 text-neutral-7 whitespace-pre-wrap">
          <div className="font-roboto" dangerouslySetInnerHTML={{ __html: props.description }} />
          <div className="mt-[20px]">
              {props.videos?.split(",").map(vid => (
                vid ? <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${vid}`}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> : <></>
              ))}
            </div>
        </p>
      </div>

      {/* Right column with 5 cols */}
      <div className="w-5/12 px-[20px] pt-[16px] pb-[20px] bg-white rounded-xl">

        {/* Image container */}
        <AppImage 
          containerWidth={454}
          containerHeight={240}
          image={props.image}
        />
        
        {/* button section */}
        <div className="w-full py-[16px] px-[8px] border-b-[2px]">
          <Link to="/contact" className="!block text-center bg-primary w-full rounded-xl text-white py-3 button-text">
            LIÊN HỆ CHÚNG TÔI
          </Link>
        </div>

        {/* contact section */}
        <div className="px-[8px] pt-[16px]">
          <div className="flex items-center gap-4">
            <img loading="lazy" src="/verified-icon.svg" alt="verified" width={16} height={16} />
            <p className="text-[18px] text-accent-1">Liên hệ đặt hàng : 0938 172 369 (Mr Hien) </p>
          </div>
          <div className="flex items-center gap-4">
            <img loading="lazy" src="/published-icon.svg" alt="verified" width={16} height={16} />
            <p className="text-[18px] text-accent-1">khanhhienktc@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}