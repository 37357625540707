import AppImageFixed from "components/image-fixed";
import { ArticleItem } from "lib/models/article-item";
import { ArticleService } from "lib/services/article.service";
import { RenderValueService } from "lib/services/render-value-service";
import { Utils } from "lib/services/utils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function CommonNewsPart() {
    const [supports, setSupports] = useState<ArticleItem[]>([]);
    const [news, setNews] = useState<ArticleItem[]>([]);

    useEffect(() => {
        ArticleService.getAllPosts().then(res => {
            setNews(Utils.EnsureNumberOfItemsInList(3, res))
        })
        ArticleService.getAllSupportArticles().then(res => {
            setSupports(Utils.EnsureNumberOfItemsInList(5, res))
        })
      }, [])

    return (
        <div className="min-h-[484px] flex gap-[10px] items-start rounded-[15px] bg-white mb-[10px]">
            <div className="w-2/3 px-[24px] py-[12px]">
                <div className="pb-3 text-primary w-full border-b-[1px] border-neutral-4 font-roboto font-bold text-[23px]">
                    Bản tin công nghiệp
                </div>
                <div className="news-grid-container">
                    <Link to={`/blogs/${news[1]?.articlePath}`}>
                        <img loading="lazy" width={208} height={112} src={`${news[1]?.outerImagePath}`} alt="news" className="w-full rounded-[10px]" />
                        <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">{news[1]?.headerText}</p>
                        <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>{RenderValueService.RenderDateOnly(news[1]?.updatedAt?.toString())}</p>
                    </Link>
                    <Link to={`/blogs/${news[0]?.articlePath}`} className="news-big-item">
                        <img loading="lazy" width={578} height={286} src={`${news[0]?.outerImagePath}`} alt="news" className="w-full rounded-[10px]" />
                        <p className="font-roboto line-clamp-2 text-neutral-8 text-[21px] font-bold mt-2">{news[0]?.headerText}</p>
                        <p className="font-roboto text-neutral-8 text-[13px] line-clamp-4 mt-2">{news[0]?.summaryText}</p>
                        <p className="mt-6 font-roboto text-neutral-8 text-[15px] font-semibold">Ngày đăng: {RenderValueService.RenderDateOnly(news[0]?.updatedAt?.toString())}</p>
                    </Link>
                    <Link to={`/blogs/${news[2]?.articlePath}`}>
                        <img loading="lazy" width={208} height={112} src={`${news[2]?.outerImagePath}`} alt="news" className="w-full rounded-[10px]" />
                        <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">{news[2]?.headerText}</p>
                        <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>{RenderValueService.RenderDateOnly(news[2]?.updatedAt?.toString())}</p>
                    </Link>
                </div>
            </div>
            <div className="w-1/3 px-[24px] py-[12px]">
                <div className="pb-3 text-primary w-full border-b-[1px] border-neutral-4 font-roboto font-bold text-[23px]">
                    Hỗ trợ kĩ thuật
                </div>
                <div className="flex flex-col">
                    {supports.map(item => (
                        <Link to={`/blogs/${item.articlePath}`} className="py-[12px] min-h-[100px] flex gap-[12px] items-center justify-between border-b-[0.25px]">
                            <div className="w-[70%] min-h-[80px] flex flex-col justify-between">
                                <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-semibold leading-tight">{item.headerText}</p>
                                <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>{RenderValueService.RenderDateOnly(item.updatedAt.toString())}</p>
                            </div>
                            <div className={`overflow-hidden relative rounded-[5px] w-[80px] h-[80px]`}>
                                <AppImageFixed image={{
                                    url: item.outerImagePath,
                                    width: 80,
                                    height: 80
                                }} />
                            </div>
                            {/* <img loading="lazy" width={80} height={80} src={`${item.outerImagePath}`} className="rounded-[10px]" alt="news" /> */}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}