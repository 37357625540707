import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel"
import { ImageMetadata } from "lib/types/image-meta"
// import AppImage from "components/image-container"

export function ImageCarousel(props: {
  images: ImageMetadata[]
}) {
  return (
    <Carousel className="relative w-full">
      <CarouselContent>
        {props.images.map((image, index) => (
          <CarouselItem key={index}>
            <div>
              <img loading="lazy" src={image.url} width="100%" height="auto" alt="image-index" />
              {/* <AppImage image={image} rounded={true} /> */}
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="absolute top-[230px] left-5 rounded-xl text-primary bg-white" />
      <CarouselNext className="absolute top-[230px] right-5 rounded-xl text-primary bg-white" />
    </Carousel>
  )
}
