export default function ServiceContainer(props: {
    serviceName: string,
    shortDescription: string
}){
    return (
        <div className="absolute flex flex-col justify-between w-[400px] py-[15px] pr-[100px] top-0 left-[20px] bg-gradient-to-r from-white to-white/0">
            <p className="text-primary text-[24px] font-semibold">{props.serviceName}</p>
            <p className="font-roboto text-neutral-6 mt-2 mb-2 text-[12px] max-w-[200px]">{props.shortDescription}</p>
            <button className="px-[20px] py-[5px] text-white rounded-xl bg-primary font-roboto font-semibold hover:bg-secondary w-max">Liên hệ</button>
        </div>
    )
}