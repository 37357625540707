import { useAppContext } from "context/ConfigurationContext";
import { ConfigurationService } from "lib/services/configuration.service";
import { Link } from "react-router-dom";

export default function FootNavigate() {
  const {configurations} = useAppContext();

  return (
    <div className="px-3 pt-4 pb-4 flex gap-12 bg-white border-b-[1px] border-solid container-padding">
      <div className="max-w-[25%]">
        <h6 className="text-primary py-1.5">CÔNG TY TNHH KTC VINA</h6>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/location.svg'} alt="location" width={25} height={24} />
          <p className="text-neutral-8 body-text">{`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL1_ADDRESS')}`}</p>
        </div>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/call.svg'} alt="call" width={25} height={24} />
          <p className="text-neutral-8 body-text">{`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL1_PHONE1')}`}</p>
        </div>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/call.svg'} alt="call" width={25} height={24} />
          <p className="text-neutral-8 body-text">{`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL1_PHONE2')}`}</p>
        </div>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/email.svg'} alt="email" width={25} height={24} />
          <p className="text-neutral-8 body-text">{`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL1_EMAIL')}`}</p>
        </div>
      </div>
      <div className="flex flex-col">
        <h6 className="text-primary py-1.5">Danh mục sản phẩm</h6>
        <Link to="/services" className="text-neutral-8 hover:text-primary body-text py-1.5">Dịch vụ</Link>
        <Link to="/products" className="text-neutral-8 hover:text-primary body-text py-1.5">Sản phẩm</Link>
        <Link to="/products-maintain" className="text-neutral-8 hover:text-primary body-text py-1.5">Công cụ bảo dưỡng</Link>
        <Link to="/blogs" className="text-neutral-8 hover:text-primary body-text py-1.5">Hỗ trợ kĩ thuật</Link>
      </div>
      <div className="flex flex-col">
        <h6 className="text-primary py-1.5">Hỗ trợ khách hàng</h6>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL3_PAYMENT')}`} className="text-neutral-8 hover:text-primary body-text py-1.5">Cách tra cứu mã vòng bi</Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL3_REFUND')}`} className="text-neutral-8 hover:text-primary body-text py-1.5">Tư vấn kỹ thuật, lắp đặt</Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL3_SHIP')}`} className="text-neutral-8 hover:text-primary body-text py-1.5">Chính sách giao hàng</Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL3_PAYMENTRULE')}`} className="text-neutral-8 hover:text-primary body-text py-1.5">Chính sách bảo hành</Link>
      </div>
      <div className="flex flex-col">
        <h6 className="text-primary py-1.5">Mạng xã hội</h6>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL4_FANPAGE')}`} className="text-neutral-8 hover:text-primary body-text py-1.5">Fanpage</Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, 'FOOTER_COL4_YOUTUBE')}`} className="text-neutral-8 hover:text-primary body-text py-1.5">Youtube</Link>
      </div>
      <div>
        <iframe title="maps" src={`${ConfigurationService.GetConfigurationValue(configurations, 'CONTACT_GGMAP_LINK')}`} width="100%" height="200" loading="lazy"></iframe>
      </div>
    </div>
  )
}