import Configuration from "lib/models/configuration";
import { ConfigurationService } from "lib/services/configuration.service";
import { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../navbar";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import Required from "components/required-field";
import ReactQuill from "react-quill";

export default function AdminConfigurationPage() {
    const navigate = useNavigate();

    const [editorContent, setEditorContent] = useState('');
    const quillRef = useRef<ReactQuill | null>(null);
    const [isMounted, setIsMounted] = useState(false);

    const [configurations, setConfigurations] = useState<Configuration[]>([]);

    useEffect(() => {
        setIsMounted(true);
        ConfigurationService.GetAllConfigurations()
        .then(res => {
            setConfigurations(res.filter(x => x.configKey !== 'ABOUT_US'));
            setEditorContent(res.filter(x => x.configKey === 'CONTACT_FULL_TEXT')[0].value);
        })
    }, [])

    const modules = useMemo(() => ({
        toolbar: {
          container: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['clean']
          ]
        }
      }), []);
  
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      // post article
      configurations.filter(x => x.configKey === 'CONTACT_FULL_TEXT')[0].value = editorContent;
      await ConfigurationService.PostConfigurations(configurations);
      alert("Cập nhật cấu hình thành công")
  
      navigate('/admin/configuration');
      window.location.reload();
    }

    const onInputChange = (e: ChangeEvent<HTMLInputElement>, configId: number): void => {
        const instance = configurations.find(x => x.id === configId);
        if(instance){
            instance.value = e.target.value;
        }

        setConfigurations([...configurations]);
    }

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                <h1>Cấu hình hệ thống</h1>
                </div>
            </div>

            {/* Form */}
            <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmit}>
                {configurations.map(configuration => (
                    <div key={configuration.id}>
                        <label htmlFor={configuration.id + 'identification'} className={CommonUsingTailwind.labelClass}>{`[${configuration.configKey}]`} {configuration.label} <Required /></label>
                        
                        {configuration.configKey === 'CONTACT_FULL_TEXT' ? (
                            <div>{(isMounted &&
                                <ReactQuill
                                    ref={quillRef}
                                    value={editorContent}
                                    onChange={setEditorContent}
                                    modules={modules}
                                    formats={[
                                    'header', 'font', 'size',
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet', 'indent',
                                    'link', 'image'
                                    ]}
                                />)}
                            </div>
                        ) : (
                            <input
                                id={configuration.id + 'identification'}
                                className={CommonUsingTailwind.inputClass}
                                type="text"
                                value={configuration.value}
                                onChange={e => onInputChange(e, configuration.id)}
                            />
                        )}
                    </div>
                ))}

                <button className={CommonUsingTailwind.sumbitButton} type="submit">sumbit</button>
            </form>
        </main>
    )
}