'use client'

import ArticleDto from "lib/models/article.dto";
import { ArticleService } from "lib/services/article.service";
import FileService from "lib/services/file-service";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../navbar";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import Required from "components/required-field";
import { ArticleType } from "lib/types/article-type";
import LoadingIndicator from "components/loading";


export default function AddArticleComponent(props: {type?: ArticleType}){
  const navigate = useNavigate();

  const [editorContent, setEditorContent] = useState('');
  const quillRef = useRef<ReactQuill | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  const handleImageUpload = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files![0];
      if (file) {
        try {
          setIsLoading(true)
          const response = await FileService.StoreFile(file);
          setIsLoading(false)

          const quill = quillRef.current?.getEditor();
          const range = quill?.getSelection();
          if(range && quill){
            quill.insertEmbed(range.index, 'image', response.outputPath);
            article.imageIds.push(response.id);
          }
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        'image': handleImageUpload
      }
    }
  }), []);

  const [article, setArticle] = useState<ArticleDto>({
    headerText: "",
    summaryText: "",
    contentData: "",
    videos: "",
    outerImageId: undefined,
    articleType: props.type?.toString() ?? "0",
    articlePath: "",
    imageIds: []
  });
  const [outerImageSrc, setOuterImageSrc] = useState<string>('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // set editor content
    article.contentData = editorContent;
    setArticle({...article});

    // post article
    await ArticleService.postArticle(article);

    alert("Tạo mới bài viết thành công")

    navigate('/admin/articles');
    window.location.reload();
  }

  const outerImageInput = useRef(null);
  const handleOuterImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files[0]) {
      setIsLoading(true)
      const fileResponse = await FileService.StoreFile(files[0]);
      setIsLoading(false)
      setOuterImageSrc(fileResponse.outputPath);
      article.outerImageId = fileResponse.id;
    }
  };


  return (
    <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
      <AdminNavbar />
      <div className="my-[20px] mx-[10px]">
        <div className="flex items-center justify-between mb-[12px]">
          <h1>Tạo mới bài viết</h1>
          {isLoading ? (<LoadingIndicator />) : (<></>)}
        </div>
      </div>

      {/* Form */}
      <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmit}>
        {/* HeaderText input */}
        <div>
          <label htmlFor="headerText" className={CommonUsingTailwind.labelClass}>Tên bài viết <Required /></label>
          <input
            id="headerText"
            className={CommonUsingTailwind.inputClass}
            type="text"
            value={article.headerText}
            onChange={e => { article.headerText = e.target.value; setArticle({ ...article }) }}
            placeholder="Tên bài viết"
          />
        </div>

        {/* articlePath input */}
        <div>
          <label htmlFor="articlePath" className={CommonUsingTailwind.labelClass}>Đường dẫn duy nhất <Required /></label>
          <input
            id="articlePath"
            className={CommonUsingTailwind.inputClass}
            type="text"
            value={article.articlePath}
            onChange={e => { article.articlePath = e.target.value; setArticle({ ...article }) }}
            placeholder="Đường dẫn"
          />
        </div>

        {/* SummaryText input */}
        <div>
          <label htmlFor="summaryText" className={CommonUsingTailwind.labelClass}>Nội dung <Required /></label>
          <input
            id="summaryText"
            className={CommonUsingTailwind.inputClass}
            type="text"
            value={article.summaryText}
            onChange={e => { article.summaryText = e.target.value; setArticle({ ...article }) }}
            placeholder="Tóm tắt"
          />
        </div>

        {/* ContentData input <simple> */}
        <div>{(isMounted &&
          <ReactQuill
            ref={quillRef}
            value={editorContent}
            onChange={setEditorContent}
            modules={modules}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]}
          />)}
        </div>

        {props.type ? (<></>) : (
          <div>
            {/* ArticleType input */}
            <label htmlFor="type" className={CommonUsingTailwind.labelClass}>Loại <Required /></label>
            <select
              id="type"
              className={CommonUsingTailwind.inputOptionClass}
              onChange={e => {
                const type = e.target.value;
                article.articleType = type;
                setArticle({ ...article })
              }
              }>
              <option value={ArticleType.article} selected>Tin tức</option>
              <option value={ArticleType.service}>Dịch vụ</option>
              <option value={ArticleType.support}>Hỗ trợ kĩ thuật</option>
            </select>
          </div>
        )}
        <div>
          {/* OuterImage input */}
          <label htmlFor="outerImageFile" className={CommonUsingTailwind.labelClass}>Hình ảnh <Required /></label>
          <input id="outerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={outerImageInput} onChange={handleOuterImageInputChange} />
          {outerImageSrc ? <img loading="lazy" width={100} height={100} src={outerImageSrc} alt='outerImage' /> : <></>}
        </div>

        {/* SummaryText input */}
        <div>
          <label htmlFor="video" className={CommonUsingTailwind.labelClass}>Videos</label>
          <input
            id="video"
            className={CommonUsingTailwind.inputClass}
            type="text"
            value={article.videos}
            onChange={e => { article.videos = e.target.value; setArticle({ ...article }) }}
            placeholder="Video"
          />
        </div>

        <button className={isLoading ? CommonUsingTailwind.sumbitButtonDisabled : CommonUsingTailwind.sumbitButton} type="submit" disabled={isLoading}>sumbit</button>
      </form>
    </main>

  )
}