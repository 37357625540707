import CommonUsingTailwind from "lib/services/common-using-tailwind";
import AdminNavbar from "./navbar";
import { useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import Configuration from "lib/models/configuration";
import { ConfigurationService } from "lib/services/configuration.service";
import Required from "components/required-field";
import ReactQuill from "react-quill";
import FileService from "lib/services/file-service";

export default function AboutUsConfigPage(){
    const navigate = useNavigate();

    const [editorContent, setEditorContent] = useState('');
    const quillRef = useRef<ReactQuill | null>(null);
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [configuration, setConfiguration] = useState<Configuration>();

    useEffect(() => {
        setIsMounted(true);
        ConfigurationService.GetAllConfigurations()
        .then(res => {
            const targetConfig = res.filter(x => x.configKey === 'ABOUT_US')[0];
            setEditorContent(targetConfig.value);
            setConfiguration(targetConfig);
        })
    }, [])

    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = async () => {
          const file = input.files![0];
          if (file) {
            try {
              setIsLoading(true)
              const response = await FileService.StoreFile(file);
              setIsLoading(false)
    
              const quill = quillRef.current?.getEditor();
              const range = quill?.getSelection();
              if(range && quill){
                quill.insertEmbed(range.index, 'image', response.outputPath);
              }
            } catch (error) {
              console.error('Error uploading image:', error);
            }
          }
        };
      };
    
      const modules = useMemo(() => ({
        toolbar: {
          container: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
          ],
          handlers: {
            'image': handleImageUpload
          }
        }
      }), []);

  
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      configuration!.value = editorContent;

      // post article
      await ConfigurationService.PostConfigurations([configuration!]);
      alert("Cập nhật cấu hình thành công")
  
      navigate('/admin/about-us');
      window.location.reload();
    }

    return (
            <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
                <AdminNavbar />
                <div className="my-[20px] mx-[10px]">
                    <div className="flex items-center justify-between mb-[12px]">
                        <h1>Cấu hình trang về chúng tôi</h1>
                    </div>
                </div>
    
                {/* Form */}
                <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="about-us" className={CommonUsingTailwind.labelClass}>{`[${configuration?.configKey}]`} {configuration?.label} <Required /></label>
                            <div>{(isMounted &&
                                <ReactQuill
                                    ref={quillRef}
                                    value={editorContent}
                                    onChange={setEditorContent}
                                    modules={modules}
                                    formats={[
                                    'header', 'font', 'size',
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet', 'indent',
                                    'link', 'image'
                                    ]}
                                />)}
                            </div>
                        </div>
    
                    <button className={isLoading ? CommonUsingTailwind.sumbitButtonDisabled : CommonUsingTailwind.sumbitButton} type="submit" disabled={isLoading}>sumbit</button>
                </form>
            </main>
    )
}