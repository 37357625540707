import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "components/ui/breadcrumb";
import HomeBreadcrumbItem from "./home-breadcrumb-item";

export default function SearchBreadcrumb() {
  return (
    <Breadcrumb className="container-padding">
      <BreadcrumbList>
        <HomeBreadcrumbItem />
        <BreadcrumbSeparator />
        <BreadcrumbItem>
            <BreadcrumbPage>Tìm kiếm</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}