import VideoItem from "lib/models/video";
import { VideoService } from "lib/services/video-service";
import { useEffect, useState } from "react";
import AdminNavbar from "../navbar";
import { Link } from "react-router-dom";

export default function AdminVideoPage(){
    const [videos, setVideos] = useState<VideoItem[]>([]);

    useEffect(() => {
        VideoService.getAll()
        .then(response => setVideos(response))
    }, [])

    const deleteProduct = async (id: number) => {
        // eslint-disable-next-line no-restricted-globals
        if(!confirm("Bạn có chắc chắn muốn xóa?")){
            return;
        }

        await VideoService.delete(id.toString());
        alert("Xóa thành công.");

        VideoService.getAll()
        .then(response => setVideos(response))
    }


    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <h1>Danh sách videos</h1>
                    <Link to="/admin/videos/new">
                        <button className="px-8 py-2 bg-blue-500 text-white rounded-xl hover:cursor:pointer">Tạo mới</button>
                    </Link>
                </div>

                {/* Table */}
                <table className="min-w-full border border-neutral-200 text-center text-md font-light text-surface bg-white">
                    <thead className="border-b border-neutral-200 font-medium">
                        <tr>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                #
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Tên
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Youtube Id
                            </th>
                            <th scope="col" className="px-6 py-4 w-[100px]"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {videos.map(video => (
                        <tr className="border-b border-neutral-200" key={video.id}>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {video.id}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {video.headerText}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {video.uniqueYoutubeId}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 flex justify-center w-[100px]">
                                <div className="flex items-center gap-4">
                                    <button onClick={() => deleteProduct(video.id)}>
                                        <img loading="lazy" width={24} height={24} src={`/v2/icons/delete.svg`} alt="delete"/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </main>
    )
}