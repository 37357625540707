import { ArticleItem } from "lib/models/article-item";
import ServiceItemCard from "./service-item-card";
import { Link } from "react-router-dom";
import { Button } from "components/ui/button";

export default function ServiceCarouselBox(
    props: {
        routerLink?: string,
        title: string,
        shortDescription: string,
        items: ArticleItem[],
        className?: string
    }
) {
    return (
        // Container
        <div className={`${props.className} mb-[10px] p-4 bg-white rounded-xl`}>
            <div className="flex items-center justify-between mb-2">
                <h3 className="text-primary">{props.title}</h3>
                <Link to={`/services`}>
                    <Button variant="outline" className="text-neutral-8 text-[18px] hover:text-primary hover:border-primary">
                        Xem tất cả
                    </Button>
                </Link>
            </div>
            {props.shortDescription ? (<div className="text-neutral-7 subtitle-1 mt-1">{props.shortDescription}</div>) : (<></>)}
            
            <div className="grid grid-cols-2 gap-[20px]">
            {props.items.map(item => (
                        <ServiceItemCard
                            key={item.id}
                            image={{
                                width: 214,
                                height: 140,
                                url: item.outerImagePath,
                                alt: item.headerText
                            }}
                            name={item.headerText}
                            detailUrl={`${props.routerLink}/${item.articlePath}`}
                            shortDescription={item.summaryText}
                        />
                    ))}
            </div>
        </div>
    );
}
