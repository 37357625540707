import axios from "axios";
import { ApiEndpoints } from "./api-endpoints";
import { FileResponse } from "../models/file-response";
import imageCompression from "browser-image-compression";
import SimpleAuthService from "./simple-check-auth.service";

export default class FileService {
  public static async StoreFile(file: File): Promise<FileResponse> {
    if(!SimpleAuthService.Check()){
      alert("Xác thực thất bại!");
      throw new Error("Auth failed");
    }

    try {
      const options = {
        maxSizeMB: 1,        // Maximum size in MB
        useWebWorker: true,  // Use multi-threading for faster compression
        // Note: We are not setting maxWidthOrHeight, so the original dimensions are preserved
      };
      const compressedFile = await imageCompression(file, options);
      const formData = new FormData();
      formData.append('file', compressedFile);
      const response = await axios.post<FileResponse>(`${ApiEndpoints.backendHost}/File`, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
      });
      response.data.outputPath = this.GetImageUrl(response.data.urlPath);
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }

  public static async StoreRawFileWithoutZip(file: File): Promise<FileResponse> {
    if(!SimpleAuthService.Check()){
      alert("Xác thực thất bại!");
      throw new Error("Auth failed");
    }

    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post<FileResponse>(`${ApiEndpoints.backendHost}/File`, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
      });
      response.data.outputPath = this.GetImageUrl(response.data.urlPath);
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }

  public static GetImageUrl(name: string): string {
    // #PROD mode
    const realName = name.replace('public/files/', '');
    const backendPath = '/laravel/public/'
    
    // #DEV mode
    // const realName = name.replace('uploads/', '');
    // const backendPath = '/uploads/'
    return `${ApiEndpoints.backendHostImage}${backendPath}${realName}`;
  }
}