export default function CertificateDetailSection() {
  return (
    <div className="flex items-center justify-between p-6">
      <div className="w-1/2 text-[14px]">
        Giấy CNĐKDN: 0311098710 - Ngày cấp: 26/08/2011 Cơ quan cấp: Phòng Đăng ký kinh doanh <br /> Sở Kế hoạch và Đầu tư TPHCM
      </div>
      <div className="flex items-center gap-3">
        <img loading="lazy" src={process.env.PUBLIC_URL + '/certificates/cert-1.svg'} alt="certificate-1" width={124} height={46} />
        <img loading="lazy" src={process.env.PUBLIC_URL + '/certificates/cert-2.svg'} alt="certificate-2" width={100} height={36} />
      </div>
    </div>
  )
}