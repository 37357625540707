import { useState } from "react"

export default function ProductTypeInformation(props: {
  type: string,
  description: string,
}) {
  const [readingMore, setReadingMore] = useState(false);
  return (
    <div className="bg-white pt-[20px] pb-[8px] px-[24px] rounded-xl mt-[10px]">
      <h4 className="text-primary">Thông tin - {props.type}</h4>
      <p className={`whitespace-pre-wrap mt-3 body-text text-neutral-7 leading-8 font-roboto ${readingMore === true ? 'line-clamp-0' : 'line-clamp-6'}`}>
        {props.description}
      </p>
      <div
        className="mt-3 mb-2 text-neutral-7 hover:text-primary underline text-center transition transition-all delay-150 hover:cursor-pointer"
        onClick={() => setReadingMore(!readingMore)}
      >{readingMore ? 'Ẩn' : 'Xem thêm'}</div>
    </div>
  )
}