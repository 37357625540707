import BannerImage from "lib/models/banner-images";
import { BannerService } from "lib/services/banner-service";
import CommonUsingTailwind from "lib/services/common-using-tailwind";

export default function AdminBannerItem(props: {banner: BannerImage}){
    const deleteBannerImage = () => {
        BannerService.delete(props.banner.id).then(_ => {alert("Xóa thành công."); window.location.reload();})
    }

    return (
        <div className="p-8 border-[2px] rounded-lg border-neutral-8">
            <div className={CommonUsingTailwind.labelClass}>Tạo lúc: {props.banner.updatedAt}</div>
            <img src={props.banner.image.urlPath} alt="Img Banner config" className="mt-2" />
            <div className="mt-2">
                <button className={CommonUsingTailwind.deleteButton} onClick={() => {deleteBannerImage()}}>Xóa</button>
            </div>
        </div>
    );
}