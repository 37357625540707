import { Link } from "react-router-dom";
import AdminNavbar from "../navbar";
import { RenderValueService } from "lib/services/render-value-service";
import { useEffect, useState } from "react";
import OuterProduct from "lib/models/outer-product";
import { OuterProductService } from "lib/services/outer-product.service";

export default function AdminProductsPage() {
    const [outerProducts, setOuterProducts] = useState<OuterProduct[]>([]);

    useEffect(() => {
        OuterProductService.getAllOuterProduct()
        .then(response => setOuterProducts(response))
    }, [])

    const deleteProduct = async (id: number) => {
        // eslint-disable-next-line no-restricted-globals
        if(!confirm("Bạn có chắc chắn muốn xóa?")){
            return;
        }

        await OuterProductService.deleteOuterProduct(id.toString());
        alert("Xóa thành công.");

        OuterProductService.getAllOuterProduct()
        .then(response => setOuterProducts(response))
    }


    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <h1>Danh sách sản phẩm</h1>
                    <Link to="/admin/products/new">
                        <button className="px-8 py-2 bg-blue-500 text-white rounded-xl hover:cursor:pointer">Tạo mới</button>
                    </Link>
                </div>

                {/* Table */}
                <table className="min-w-full border border-neutral-200 text-center text-md font-light text-surface bg-white">
                    <thead className="border-b border-neutral-200 font-medium">
                        <tr>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                #
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Tên
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Đường dẫn
                            </th>
                            <th scope="col" className="border-e border-neutral-200 w-[500px] px-6 py-4">
                                Tóm tắt
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Cập nhật lần cuối lúc
                            </th>
                            <th scope="col" className="px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {outerProducts.map(outerProduct => (
                        <tr className="border-b border-neutral-200" key={outerProduct.id}>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {outerProduct.id}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {outerProduct.name}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {outerProduct.namePath}
                            </td>
                            <td
                                className="w-[500px] border-e border-neutral-200 px-6 py-4">
                                <p className="line-clamp-1">
                                    {outerProduct.summaryDetail}
                                </p>
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {RenderValueService.RenderDateTime(outerProduct.updatedAt.toString())}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 flex justify-center w-[120px]">
                                <div className="flex items-center gap-4">
                                    <Link to={`/admin/products/${outerProduct.id}`}>
                                        <img loading="lazy" width={24} height={24} src={`/v2/icons/update.svg`} alt="update"/>
                                    </Link>
                                    <button onClick={() => deleteProduct(outerProduct.id)}>
                                        <img loading="lazy" width={24} height={24} src={`/v2/icons/delete.svg`} alt="delete"/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </main>
    )
}