import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import MainContentLayout from "./(common)/main-content-layout";
import AppNavbar from "./(landing-page)/navbar/app-navbar";
import GenericTopSection from "./(common)/generic-top-section";
import AppFooter from "./(landing-page)/footer/app-footer";
import { useParams } from "react-router-dom";
import TopMostBanner from "./(landing-page)/top-most/top-most-banner";

export default function VideoPage() {
    const { id } = useParams<{ id: string }>();

    return (
        <>
            <TopMostBanner />
            <AppNavbar />
            <MainContentLayout>
                <div className="mt-0.5 py-1 rounded-xl">
                    <OneLevelBreadcrumb label="Video" />
                </div>

                {/* Main section */}
                <main className="container-padding my-[10px]">
                    <GenericTopSection sectionHeader="Video" />
                    <div className="flex w-full h-[80vh] gap-[20px] w-full">
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${id}`}
                                title="YouTube video"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                    </div>
                </main>

                <AppFooter />
            </MainContentLayout>
        </>
    )
}