import axios from "axios";
import BannerImage from "lib/models/banner-images";
import { ApiEndpoints } from "./api-endpoints";
import FileService from "./file-service";
import SimpleAuthService from "./simple-check-auth.service";

export class BannerService {
    public static async getAll() : Promise<BannerImage[]> {
        const response = await axios.get<BannerImage[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.banner}`);
        return response.data ? response.data.map(x => {
            x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
            return x;
        }) : [];
    }

    public static async post(imageId: number) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.banner}`, {imageId});
    }

    public static async delete(id: number) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.delete(`${ApiEndpoints.backendHost}${ApiEndpoints.banner}/${id}`);
    }
}