import AppImage from "components/image-container"
import AppImageFixed from "components/image-fixed"
import { Button } from "components/ui/button"
import { ImageMetadata } from "lib/types/image-meta"
import { Link } from "react-router-dom"

export default function ServiceItemCard(props: {
    image: ImageMetadata,
    name: string,
    shortDescription: string, 
    detailUrl: string
}){
    return (
        <div className="p-3 flex items-center gap-6 ">
            <div className={`overflow-hidden relative rounded-[10px] w-[214px] h-[140px]`}>
                <AppImageFixed image={props.image} />
            </div>

            {/* Content */}
            <div className="w-[60%] h-[140px] flex flex-col justify-around">
                <div>
                    <p className="text-neutral-7 font-medium text-[25px] line-clamp-1">{props.name}</p>
                    <p className="text-neutral-7 text-[16px] leading-6 my-3 font-roboto line-clamp-2">{props.shortDescription}</p>
                </div>
                <Link to={props.detailUrl}>
                    <Button variant="outline" className="text-neutral-8 hover:text-primary hover:border-primary">Xem chi tiết</Button>
                </Link>
            </div>
        </div>
    )
}