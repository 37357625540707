import { Button } from "components/ui/button";
import { Carousel, CarouselContent, CarouselItem, TopCarouselNext, TopCarouselPrevious } from "components/ui/carousel";
import { GenericServiceItem } from "lib/types/generic-service-item";
import ProductItemCard from "components/product-item-card";

export default function ProductCarouselDoubleLineItems(props: {
  items: GenericServiceItem[][]
}) {
  return (
    <div>
      <Carousel>
          {/* Buttons section */}
          <div className="flex justify-between mt-[20px] mb-[24px]">
            <Button variant="outline" className="text-neutral-8 hover:text-primary hover:border-primary">Xem tất cả</Button>
            <div className="flex gap-2">
              <TopCarouselPrevious></TopCarouselPrevious>
              <TopCarouselNext></TopCarouselNext>
            </div>
          </div>
          <CarouselContent className="items-start justify-stretch">
            {props.items.map(item => (
              <div className="min-w-0 shrink-0 grow-0 basis-1/5" key={ item[0].routerLink }>
                <CarouselItem>
                  <ProductItemCard
                    image={{
                      width: 199,
                      height: 160,
                      url: item[0].image.url,
                      alt: item[0].image.alt
                    }}
                    name={item[0].name}
                    detailUrl={item[0].routerLink}
                  />
                </CarouselItem>
                {item[1] ? (<CarouselItem className="mt-3">
                  <ProductItemCard
                    image={{
                      width: 199,
                      height: 160,
                      url: item[1].image.url,
                      alt: item[1].image.alt
                    }}
                    name={item[1].name}
                    detailUrl={item[1].routerLink}
                  />
                </CarouselItem>) : <></>}
                
              </div>
            ))}
          </CarouselContent>
        </Carousel>
    </div>
  )
}