import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import GenericTopSection from "pages/(common)/generic-top-section";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import AllPostsSection from "./(sections)/all-posts-section";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import TopMostBanner from "pages/(landing-page)/top-most/top-most-banner";
import { useLocation } from "react-router-dom";
import CommonNewsPart from "pages/(landing-page)/news/(section)/news-section";
import { ArticleType } from "lib/types/article-type";

export default function AllBlogsPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const articleType = queryParams.get('type'); //[support, news]

    return (
        <>
          <TopMostBanner />
          <AppNavbar />
          <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
              <OneLevelBreadcrumb label={articleType === 'support' ? 'Hỗ trợ kĩ thuật' : 'Tin tức'} />
            </div>
    
            {/* Main section */}
            <main className="container-padding mt-[10px] !mb-[20px]">
              <GenericTopSection
                sectionHeader={articleType === 'support' ? 'Hỗ trợ kĩ thuật' : 'Tin tức'}
                // image={
                //   {
                //     url: "/v2/all-services-banner.svg",
                //     width: 1204,
                //     height: 240
                //   }
                // }  
              />
              {articleType === 'support' ? <></> : <CommonNewsPart />}
              <AllPostsSection type={articleType === 'support' ? ArticleType.support : ArticleType.article} />
            </main>
            
            <AppFooter />
          </MainContentLayout>
    
        </>
      )
}