import AppImage from "components/image-container"
import { ImageMetadata } from "lib/types/image-meta"

export default function GenericTopSection(props: {
  sectionHeader: string,
  image?: ImageMetadata,
  notFill?: boolean,
  className?: string
}) {
  return (
    <div className={`${props.className}`}>
      <h3 className="mb-[10px] py-2 px-4 text-neutral-8 bg-white rounded-xl">
        {props.sectionHeader}
      </h3>
      {props.image ? (
        // <AppImage containerWidth={props.notFill ? props.image.width : undefined} containerHeight={props.notFill ? props.image.height : undefined} image={props.image} />
        <img loading="lazy" src={props.image.url} alt="product" width={props.image.width} height={props.image.height} />
      ) : <></>}
      
    </div>
  )
}