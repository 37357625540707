import { ImageMetadata } from "lib/types/image-meta";

export default function AppImageFixed(props: {
  image: ImageMetadata
}) {
  return (
    <img
      src={props.image.url}
      loading="lazy"
      alt={props.image.alt ?? 'image-text'}
      width="100%"
      style={{
        objectFit: "cover",
        display: "block"
      }}
      className="!h-full overflow-hidden !rounded-[10px]"
    />
  )
}
// 