export class Utils {
    public static EnsureNumberOfItemsInList<T>(numberOfItems: number, target: ArrayLike<T>){
        const result: T[] = Array.from(target); // Convert ArrayLike to an array

        if (result.length === 0) {
            throw new Error("Target array cannot be empty.");
        }

        if (result.length > numberOfItems) {
            // Trim the array if it has more items than needed
            return result.slice(0, numberOfItems);
        }

        const lastItem = result[result.length - 1];

        while (result.length < numberOfItems) {
            result.push(lastItem); // Fill with the last item if needed
        }

        return result;
    }
}