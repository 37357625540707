import CertificateDetailSection from "./certificate-detail-section";
import CertificateSection from "./certificate-section";
import Copyright from "./copyright";
import FooterImage from "./footer-image";
import FootNavigate from "./footer-navigate";

export default function AppFooter() {
  return (
    <div className="bg-white">
      <FooterImage />
      <CertificateSection />
      <FootNavigate />
      <CertificateDetailSection />
      <Copyright />
    </div>
  )
}