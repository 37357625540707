import { ImageMetadata } from "lib/types/image-meta";

export default function AppImage(props: {
  containerWidth?: number,
  containerHeight?: number,
  image: ImageMetadata,
  className?: string,
  rounded?: boolean
}) {
  if(props.containerHeight && props.containerWidth){
    return (
      <div className={`w-[${props.containerWidth}px] ${props.className ?? ''}`}>
        <img
          src={props.image.url}
          width={props.image.width}
          height={props.image.height}
          loading="lazy"
          alt={props.image.alt ?? 'image-text'}
          style={{
            objectFit: "contain",
            width: props.containerWidth,
            height: props.containerHeight
          }}
          className={`${props.rounded ? 'rounded-xl' : ''}`}
        />
      </div>
    )
  }

  return (
    <div className={`w-full ${props.className ?? ''}`}>
      <img
        src={props.image.url}
        loading="lazy"
        width="100%"
        height={props.image.height}
        alt={props.image.alt ?? 'image-text'}
        className={`${!props.rounded ? 'rounded-xl' : ''}`}
        style={{
          objectFit: "contain",
          width: "100%",
          height: props.image.height
        }}
      />
    </div>
  )
}