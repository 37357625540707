export default class CommonUsingTailwind {
    static get inputClass(): string {
        return 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-none'
    }

    static get fileInputClass(): string {
        return '!mb-[10px] p-2.5 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none';
    }

    static get labelClass(): string {
        return 'block mb-2 text-sm font-medium text-gray-900';
    }

    static get inputOptionClass(): string {
        return 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5';
    }

    static get sumbitButton(): string {
        return 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2';
    }

    static get sumbitButtonDisabled(): string {
        return 'text-white bg-blue-300 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2';
    }

    static get deleteButton(): string {
        return 'text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-center hover:cursor-pointer';
    }
}