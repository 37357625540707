export default class ReferenceData {
    public static readonly NAV_SP_1 = "NAV_SP_1";
    public static readonly NAV_SP_2 = "NAV_SP_2";
    public static readonly NAV_SP_3 = "NAV_SP_3";
    public static readonly NAV_SP_4 = "NAV_SP_4";
    public static readonly NAV_SP_5 = "NAV_SP_5";
    public static readonly NAV_SP_6 = "NAV_SP_6";
    public static readonly NAV_SP_7 = "NAV_SP_7";
    public static readonly NAV_DV_1 = "NAV_DV_1";
    public static readonly NAV_DV_2 = "NAV_DV_2";
    public static readonly NAV_DV_3 = "NAV_DV_3";
    public static readonly NAV_DV_4 = "NAV_DV_4";
    public static readonly NAV_CCBD_1 = "NAV_CCBD_1";
    public static readonly NAV_CCBD_2 = "NAV_CCBD_2";
    public static readonly NAV_CCBD_3 = "NAV_CCBD_3";
    public static readonly BLOG_1 = "BLOG_1";
    public static readonly BLOG_2 = "BLOG_2";
    public static readonly BLOG_BIG = "BLOG_BIG";
    public static readonly BLOG_RIGHT_1 = "BLOG_RIGHT_1";
    public static readonly BLOG_RIGHT_2 = "BLOG_RIGHT_2";
    public static readonly BLOG_RIGHT_3 = "BLOG_RIGHT_3";
    public static readonly BLOG_RIGHT_4 = "BLOG_RIGHT_4";
    public static readonly SPDV_SP_1 = "SPDV_SP_1";
    public static readonly SPDV_SP_2 = "SPDV_SP_2";
    public static readonly SPDV_SP_3 = "SPDV_SP_3";
    public static readonly SPDV_SP_4 = "SPDV_SP_4";
    public static readonly SPDV_DV_1 = "SPDV_DV_1";
    public static readonly SPDV_DV_2 = "SPDV_DV_2";
    public static readonly VIDEO_1 = "VIDEO_1";
    public static readonly VIDEO_2 = "VIDEO_2";
    public static readonly VIDEO_3 = "VIDEO_3";
    public static readonly VIDEO_4 = "VIDEO_4";
}