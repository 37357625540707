import { ArticleItem } from "lib/models/article-item";
import { ArticleService } from "lib/services/article.service";
import { ArticleType } from "lib/types/article-type";
import { ChangeEvent, useEffect, useState } from "react";
import AdminNavbar from "../navbar";
import { Link } from "react-router-dom";
import { RenderValueService } from "lib/services/render-value-service";

export default function ManageArticles(){
    const [articles, setArticles] = useState<ArticleItem[]>([]);
    const [articleType, setArticleType] = useState<ArticleType | undefined>(undefined);

    const onSelectedOption = (e: ChangeEvent<HTMLSelectElement>) => {
        if(e.target.value){
            setArticleType(Number(e.target.value))
        }
    }

    useEffect(() => {
        if(articleType === ArticleType.service){
            ArticleService.getAllServices()
            .then(response => setArticles(response))
        } else if(articleType === ArticleType.article) {
            ArticleService.getAllPosts()
            .then(response => setArticles(response))
        } else if(articleType === ArticleType.support) {
            ArticleService.getAllSupportArticles()
            .then(response => setArticles(response))
        } else {
            ArticleService.getAllArticles()
            .then(response => setArticles(response))
        }
    }, [articleType])

    useEffect(() => {
        ArticleService.getAllArticles()
        .then(response => setArticles(response))
    }, []);

    const deleteArticle = async (id: number) => {
        // eslint-disable-next-line no-restricted-globals
        if(!confirm("Bạn có chắc chắn muốn xóa?")){
            return;
        }

        await ArticleService.DeleteArticle(id.toString());

        alert("Xóa thành công.");

        ArticleService.getAllArticles()
        .then(response => setArticles(response))
    }

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <div className="flex items-center gap-3">
                        <h1 className="w-[250px]">Danh sách bài viết</h1>
                        <select 
                            id="countries" 
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-max p-2.5"
                            value={articleType}
                            onChange={onSelectedOption}
                        >
                            <option selected value={undefined}>Tất cả</option>
                            <option value={ArticleType.article}>Tin tức</option>
                            <option value={ArticleType.service}>Dịch vụ</option>
                            <option value={ArticleType.support}>Hỗ trợ kĩ thuật</option>
                        </select>
                    </div>
                    <Link to="/admin/articles/new">
                        <button className="px-8 py-2 bg-blue-500 text-white rounded-xl hover:cursor:pointer">Tạo mới</button>
                    </Link>
                </div>

                {/* Table */}
                <table className="min-w-full border border-neutral-200 text-center text-md font-light text-surface bg-white">
                    <thead className="border-b border-neutral-200 font-medium">
                        <tr>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                #
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Tên
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Đường dẫn
                            </th>
                            <th scope="col" className="border-e border-neutral-200 w-[500px] px-6 py-4">
                                Tóm tắt
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Loại
                            </th>
                            <th scope="col" className="border-e border-neutral-200 px-6 py-4">
                                Cập nhật lần cuối lúc
                            </th>
                            <th scope="col" className="px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {articles.map(article => (
                        <tr className="border-b border-neutral-200" key={article.id}>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {article.id}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {article.headerText}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4">
                                {article.articlePath}
                            </td>
                            <td
                                className="w-[500px] border-e border-neutral-200 px-6 py-4">
                                <p className="line-clamp-1">
                                    {article.summaryText}
                                </p>
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {article.articleType === "1" ? 'Dịch vụ' : 'Tin tức & Hỗ trợ kĩ thuật'}
                            </td>
                            <td
                                className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium">
                                {RenderValueService.RenderDateTime(article.updatedAt.toString())}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 flex justify-center w-[120px]">
                                <div className="flex items-center gap-4">
                                    <Link to={`/admin/articles/${article.id}`}>
                                        <img loading="lazy" width={24} height={24} src={`/v2/icons/update.svg`} alt="update"/>
                                    </Link>
                                    <button onClick={() => deleteArticle(article.id)}>
                                        <img loading="lazy" width={24} height={24} src={`/v2/icons/delete.svg`} alt="delete"/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </main>
        
    )
}