import InnerProductResponse from "lib/models/inner-product-response";
import { OuterProductService } from "lib/services/outer-product.service";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetailInformation from "./(sections)/product-detail-information";
import { ImageMetadata } from "lib/types/image-meta";
import NotFound from "components/notfound";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import MainContentLayout from "pages/(common)/main-content-layout";
import ProductBreadcrumb from "components/breadcrumb/products-breadcrumb";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import MoreProductSection from "./(sections)/more-products-section";
import ProductDetailTabsView from "./(sections)/product-detail-tabs-view";
import TopMostBanner from "pages/(landing-page)/top-most/top-most-banner";

export default function ProductDetailPage() {
    const {type, id} = useParams<{type: string, id: string}>();

    const [innerProductResponse, setInnerProductResponse] = useState<InnerProductResponse>();
    const [images, setImages] = useState<ImageMetadata[]>([]);

    useEffect(() => {
        if(id){
            OuterProductService.getInnerProductByPath(id)
            .then(res => setInnerProductResponse(res))
        }
    }, [type, id])

    useEffect(() => {
        if(innerProductResponse){
            const imagesResponse = innerProductResponse.innerProductImages?.map(x => ({
                width: 487,
                height: 468,
                url: x.image.urlPath,
                alt: x.image.fileName
              } as ImageMetadata)) ?? [];

              setImages(imagesResponse);
        }
    }, [innerProductResponse])

    const typeRouteMetadata: BreadcrumbRouteMetadata = {
        title: type ?? '',
        routerLink: `/products/${type}`
      };
      const detailRouteMetadata: BreadcrumbRouteMetadata = {
        title: id ?? '',
        routerLink: '#'
      }

    
      if(!innerProductResponse){
        return NotFound();
      }
    
      return (
        <>
          <TopMostBanner />
          <AppNavbar />
          <MainContentLayout>
            {/* Breadcrumb */}
            <div className="mt-0.5 py-1 rounded-xl">
              <ProductBreadcrumb type={typeRouteMetadata} detail={detailRouteMetadata} />
            </div>
    
            {/* Main section */}
            <main className="container-padding mt-[10px] !mb-[20px]">
              <h3 className="mb-[10px] py-2 px-4 text-neutral-8 bg-white rounded-xl">
                {innerProductResponse.name}
              </h3>
    
              <ProductDetailInformation
                isAvailable = { true }
                fullDescription = { innerProductResponse.shortDescription ?? '' }
                technicalInfo = { {
                  type: innerProductResponse.outerProductActualName ?? 'N/A',
                  brand: innerProductResponse.brand ?? 'N/A',
                  code: innerProductResponse.productSeries ?? 'N/A',
                  from: innerProductResponse.madeIn ?? 'N/A'
                } }
                images = { images }
                type = { innerProductResponse.outerProductName }
              />
              <ProductDetailTabsView
                detailDescription = { innerProductResponse.description }
                videos = { innerProductResponse.videos }
                technicalInfo = { {
                  type: innerProductResponse.outerProductName,
                  brand: innerProductResponse.brand ?? 'N/A',
                  code: innerProductResponse.productSeries ?? 'N/A',
                  from: innerProductResponse.madeIn ?? 'N/A'
                } }
              />
              <MoreProductSection 
                sameProducts = { innerProductResponse.relatedProducts?.map(x => ({
                  id: x.id,
                  name: x.name,
                  description: '',
                  image: x.image,
                  namePath: x.namePath,
                  innerProductImages: [],
                  outerProductId: 0
                })) ?? [] }
                typeRouterLink = {`/products/${type}`}
              />
            </main>
    
            <AppFooter />
          </MainContentLayout>
        </>
      )
}