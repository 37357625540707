import { useAppContext } from "context/ConfigurationContext";
import { ConfigurationService } from "lib/services/configuration.service";

export default function TopMostBanner() {
  const {configurations} = useAppContext();

  const navigateHome = () => {
    window.location.href = "https://www.ktcvinabearings.com/";
  }

  return (
    <div className='w-full h-[104px] flex justify-between container-padding'>
      <img loading="lazy" src='/v2/top-logo.svg' width={205} height={70} alt='top-logo' className="hover:cursor-pointer" onClick={navigateHome}/>
      <div className='text-[#F1AD0F] h-full flex items-center text-[28px] font-roboto italic font-[500]'>{'Hiệu quả trên từng chuyển động'}</div>
      <div className='flex justify-between items-center gap-[40px]'>
        {/* Hotline */}
        <div className='flex items-center gap-3'>
          <img loading="lazy" src={process.env.PUBLIC_URL + '/v2/top-call-icon.svg'} width={52} height={52} alt='top-call' />
          <div>
            <p className='text-neutral-9'>{'Hotline tư vấn'}</p>
            <p className='text-primary'>{`${ConfigurationService.GetConfigurationValue(configurations, 'PHONE') ?? ''} (Mr Hien)`}</p>
          </div>
        </div>

        {/* Item cart */}
        {/* <div className='relative'>
          <img loading="lazy" src={process.env.PUBLIC_URL + '/v2/top-cart.svg'} width={21} height={26} alt='top-cart' />
          <div className='w-[14px] h-[14px] rounded-full bg-secondary absolute bottom-[-5px] right-[-5px] text-[11px] text-center text-neutral-7'>3</div>
        </div> */}
      </div>
    </div>
  );
}
