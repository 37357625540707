import axios from "axios";
import { ArticleItem } from "../models/article-item";
import { ApiEndpoints } from "./api-endpoints";
import ArticleDto from "../models/article.dto";
import ArticleDetail from "../models/article-detail";
import FileService from "./file-service";
import SimpleAuthService from "./simple-check-auth.service";

export class ArticleService {
    public static async getAllArticles() : Promise<ArticleItem[]> {
        const response = await axios.get<ArticleItem[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.allArticles}`);
        return response.data ? response.data.map(x => {
            x.outerImagePath = FileService.GetImageUrl(x.outerImagePath);
            return x;
        }) : [];
    }

    public static async getAllServices(): Promise<ArticleItem[]> {
        const response = await axios.get<ArticleItem[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.allServices}`);
        return response.data ? response.data.map(x => {
            x.outerImagePath = FileService.GetImageUrl(x.outerImagePath);
            return x;
        }) : [];
    }

    public static async getAllPosts(): Promise<ArticleItem[]> {
        const response = await axios.get<ArticleItem[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.allPosts}`);
        return response.data ? response.data.map(x => {
            x.outerImagePath = FileService.GetImageUrl(x.outerImagePath);
            return x;
        }) : [];
    }

    public static async getAllSupportArticles(): Promise<ArticleItem[]> {
        const response = await axios.get<ArticleItem[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.allSupportArticles}`);
        return response.data ? response.data.map(x => {
            x.outerImagePath = FileService.GetImageUrl(x.outerImagePath);
            return x;
        }) : [];
    }

    public static async getArticleById(id: string) : Promise<ArticleDetail> {
        const response = await axios.get<ArticleDetail>(`${ApiEndpoints.backendHost}${ApiEndpoints.article}/${id}`);
        response.data.outerImage.urlPath = FileService.GetImageUrl(response.data.outerImage.urlPath);
        return response.data;
    }

    public static async getArticleUniquePath(path: string) : Promise<ArticleDetail> {
        const response = await axios.get<ArticleDetail>(`${ApiEndpoints.backendHost}${ApiEndpoints.articleByPath}/${path}`);
        response.data.outerImage.urlPath = FileService.GetImageUrl(response.data.outerImage.urlPath);
        return response.data;
    }

    public static async postArticle(article: ArticleDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.article}`, article);
    }

    public static async updateArticle(id:string, article: ArticleDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.put(`${ApiEndpoints.backendHost}${ApiEndpoints.article}/${id}`, article);
    }

    public static async DeleteArticle(id: string) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.delete(`${ApiEndpoints.backendHost}${ApiEndpoints.article}/${id}`);
    }
}