import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import MainContentLayout from "./(common)/main-content-layout";
import AppNavbar from "./(landing-page)/navbar/app-navbar";
import GenericTopSection from "./(common)/generic-top-section";
import AppFooter from "./(landing-page)/footer/app-footer";
import TopMostBanner from "./(landing-page)/top-most/top-most-banner";
import { useAppContext } from "context/ConfigurationContext";
import { ConfigurationService } from "lib/services/configuration.service";

export default function ContactPage() {
    const {configurations} = useAppContext();

    return (
        <>
        <TopMostBanner />
        <AppNavbar />
        <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
            <OneLevelBreadcrumb label="Liên hệ" />
            </div>

            {/* Main section */}
            <main className="container-padding my-[10px] !mb-[20px]">
                <GenericTopSection sectionHeader="Liên hệ"/>
                <div className="flex gap-[20px] w-full">
                    <div className="w-[60%] bg-white rounded-lg p-[20px] text-[18px] whitespace-pre-wrap font-roboto rounded-[15px]">
                        <div className="font-roboto text-neutral-7" dangerouslySetInnerHTML={{ __html: ConfigurationService.GetConfigurationValue(configurations, 'CONTACT_FULL_TEXT') ?? ''}} />
                    </div>
                    <div className="w-[40%] bg-white rounded-lg p-[20px] rounded-[15px]">
                        <div className="w-full h-auto">
                            <iframe title="maps" src={`${ConfigurationService.GetConfigurationValue(configurations, 'CONTACT_GGMAP_LINK')}`} width="100%" height="440" loading="lazy"></iframe>
                        </div>
                        <div className="my-[10px]">{`${ConfigurationService.GetConfigurationValue(configurations, 'CONTACT_ADDRESS')}`}</div>
                        {/* button section */}
                        <div className="w-full py-[16px] px-[8px] border-b-[2px]">
                        <a href={`https://maps.app.goo.gl/Rztr7ZXsTpUzP5yd6`} className="bg-primary text-center block w-full rounded-xl text-white py-3 button-text">
                            Tìm đường đến công ty
                        </a>
                        </div>
                    </div>
                </div>
            </main>

            <AppFooter />
        </MainContentLayout>
        </>
    )
}