import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import "./index.scss"
import ProductItemCard from "components/product-item-card"
import { OuterProductService } from "lib/services/outer-product.service"
import InnerProduct from "lib/models/inner-product";
import { useEffect, useState } from "react";

export default function OutstandingProducts() {
  const [innerProducts, setInnerProducts] = useState<InnerProduct[]>([]);

  useEffect(() => {
    OuterProductService.getInnerProductOutstanding().then(res => setInnerProducts(res))
  }, [])

  return (
    <div className="w-full bg-neutral-2 !mb-[-20px]">
      <div className='pt-[32px] pb-[64px] container-padding'>
          <div className="flex flex-col items-center">
          <p className="text-[30px] text-neutral-9 font-semibold">SẢN PHẨM PHỔ BIẾN</p>
        </div>
        <div className="w-full flex justify-center mb-[10px]">
          <img loading="lazy" src={`v2/operate-icon.svg`} width={32} height={32} alt="operate-icon" />
        </div>
        <div className="px-[24px] outstanding-grid-container">
              {innerProducts.map(item => {
                  return (
                      <ProductItemCard 
                          key={item.name}
                          image={{
                              url:item.image.urlPath,
                              width: 198,
                              height: 160,
                              alt: item.name
                          }}
                          name={item.name}
                          detailUrl={`/products/${item.outerProductName}/${item.namePath}`}
                      />
                  )
              })}
            </div>
      </div>
    </div>
  )
}