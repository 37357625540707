import axios from "axios";
import Configuration from "../models/configuration";
import { ApiEndpoints } from "./api-endpoints";
import SimpleAuthService from "./simple-check-auth.service";

export class ConfigurationService {
    public static async GetAllConfigurations(): Promise<Configuration[]> {
        const response = await axios.get<Configuration[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.configuration}`);
        return response.data;
    }

    public static async PostConfigurations(data: Configuration[]): Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        const response = await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.configuration}`, data);
        return response.data;
    }

    public static GetConfigurationValue(data: Configuration[], configKey: string): string | undefined {
        return data.find(x => x.configKey === configKey)?.value;
    }
}