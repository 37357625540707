// ScrollToTop.js
import { useState, useEffect } from 'react';

const ScrollTopHelper = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled down
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <button
                    className='!z-[1000]'
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '8rem',
                        right: '2rem',
                        padding: '0.5rem 1rem',
                        fontSize: '1.5rem',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: 'none',
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
                    }}
                >
                    <svg width="20px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L11.6464 3.64645L12 3.29289L12.3536 3.64645L12 4ZM12.5 19C12.5 19.2761 12.2761 19.5 12 19.5C11.7239 19.5 11.5 19.2761 11.5 19L12.5 19ZM5.64645 9.64645L11.6464 3.64645L12.3536 4.35355L6.35355 10.3536L5.64645 9.64645ZM12.3536 3.64645L18.3536 9.64645L17.6464 10.3536L11.6464 4.35355L12.3536 3.64645ZM12.5 4L12.5 19L11.5 19L11.5 4L12.5 4Z" fill="#000000"/>
</svg>
                </button>
            )}
        </>
    );
};

export default ScrollTopHelper;
