export class RenderValueService {
    public static RenderArticleType(articleType: string): string {
        return articleType === "Article" ? 'Bài viết' : 'Dịch vụ';
    }

    public static RenderDateTime(dateString: string): string {
        return new Date(dateString).toLocaleString('vi-VN');
    }

    public static RenderDateOnly(dateString: string): string {
        return new Date(dateString).toLocaleString('vi-VN').split(" ")[1];
    }
}