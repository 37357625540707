import ProductCarouselBox from "components/carousel/product-carousel/product-carousel-box"
import InnerProduct from "lib/models/inner-product"

export default function MoreProductSection(props: {
  typeRouterLink: string,
  sameProducts: InnerProduct[]
}) {
  return (
    <div className="mt-[10px]">
      {/* San pham cung loai */}
      <ProductCarouselBox
        title = "Sản phẩm cùng loại"
        items = { props.sameProducts }
        shortDescription = ""
        routerLink = { props.typeRouterLink }
      ></ProductCarouselBox>
    </div>
  )
}