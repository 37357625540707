import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import SearchPage from './pages/SearchPage';
import VideoPage from './pages/VideoPage';
import AdminPage from './pages/admin/AdminPage';
import AdminConfigurationPage from './pages/admin/configuration/AdminConfigurationPage';
import AdminArticlesPage from './pages/admin/article/AdminArticlesPage';
import AdminArticleDetailPage from './pages/admin/article/AdminArticleDetailPage';
import AdminNewArticlePage from './pages/admin/article/AdminNewArticlePage';
import AdminProductsPage from './pages/admin/product/AdminProductsPage';
import AdminNewOuterProductPage from './pages/admin/product/AdminNewOuterProductPage';
import AdminProductDetailPage from './pages/admin/product/detail/AdminProductDetailPage';
import AdminNewInnerProductPage from './pages/admin/product/detail/AdminNewInnerProductPage';
import AllBlogsPage from './pages/blogs/AllBlogsPage';
import BlogDetailPage from './pages/blogs/BlogDetailPage';
import AllServicesPage from './pages/services/AllServicesPage';
import ServiceDetailPage from './pages/services/ServiceDetailPage';
import AllProductPage from './pages/products/AllProductPage';
import ProductTypePage from './pages/products/ProductTypePage';
import ProductDetailPage from './pages/products/ProductDetailPage';
import AdminBannerPage from 'pages/admin/banner/AdminBannerPage';
import AdminSubscriptionsPage from 'pages/admin/subscriptions/AdminSubscriptionsPage';
import AdminVideoPage from 'pages/admin/video/AdminVideoPage';
import AdminNewVideoPage from 'pages/admin/video/AdminNewVideoPage';
import { useEffect } from 'react';
import SimpleAuthService from 'lib/services/simple-check-auth.service';
import AllProductMaintainPage from 'pages/products/AllProductMaintainPage';
import AboutUsConfigPage from 'pages/admin/AboutUsConfigPage';
import CreateNewServicePage from 'pages/admin/article/service/CreateNewServicePage';
import AllNewsPage from 'pages/admin/article/news/AllNewsPage';
import CreateNewNewsPage from 'pages/admin/article/news/CreateNewServicePage';
import AllSupportsPage from 'pages/admin/article/supports/AllSupportsPage';
import CreateNewSupportPage from 'pages/admin/article/supports/CreateNewSupportPage';
import AllMaintainsPage from 'pages/admin/product/maintain/AllMaintainsPage';
import AdminAllProductsPage from 'pages/admin/product/product/AllProductsPage';
import AdminAllServicesPage from 'pages/admin/article/service/AllServicesPage';
import LoginPage from 'pages/LoginPage';
import ScrollTopHelper from 'components/scroll-helper';
import UpdateInnerProductSection from 'pages/admin/product/detail/update-inner-product-section';

const RouteAlert = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/admin')) {
      if(!SimpleAuthService.Check()){
        navigate("/admin-login");
      }
    }
    window.scrollTo(0, 0); // Scroll to the top-left corner
  }, [location]);

  return null; // No UI rendering needed
};

function App() {

  return (
    <>
      <Router>
        <RouteAlert />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/admin-login" element={<LoginPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/video/:id" element={<VideoPage />} />
          <Route path="/search/:name" element={<SearchPage />} />
          <Route path="/blogs" element={<AllBlogsPage />} />
          <Route path="/blogs/:id" element={<BlogDetailPage />} />
          <Route path="/services" element={<AllServicesPage />} />
          <Route path="/services/:id" element={<ServiceDetailPage />} />
          <Route path="/products" element={<AllProductPage />} />
          <Route path="/products-maintain" element={<AllProductMaintainPage />} />
          <Route path="/products/:type" element={<ProductTypePage />} />
          <Route path="/products/:type/:id" element={<ProductDetailPage />} />

          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/about-us" element={<AboutUsConfigPage />} />
          <Route path="/admin/configuration" element={<AdminConfigurationPage />} />

          <Route path="/admin/articles" element={<AdminArticlesPage />} />
          <Route path="/admin/articles/:id" element={<AdminArticleDetailPage />} />
          <Route path="/admin/articles/new" element={<AdminNewArticlePage />} />

          <Route path="/admin/services" element={<AdminAllServicesPage />} />
          <Route path="/admin/services/new" element={<CreateNewServicePage />} />

          <Route path="/admin/news" element={<AllNewsPage />} />
          <Route path="/admin/news/new" element={<CreateNewNewsPage />} />

          <Route path="/admin/supports" element={<AllSupportsPage />} />
          <Route path="/admin/supports/new" element={<CreateNewSupportPage />} />


          <Route path="/admin/products" element={<AdminProductsPage />} />
          <Route path="/admin/all-products" element={<AdminAllProductsPage />} />
          <Route path="/admin/all-maintainproducts" element={<AllMaintainsPage />} />



          <Route path="/admin/products/new" element={<AdminNewOuterProductPage />} />
          <Route path="/admin/products/:outerId" element={<AdminProductDetailPage />} />
          <Route path="/admin/products/:outerId/:innerId" element={<UpdateInnerProductSection />} />
          <Route path="/admin/products/:outerId/new" element={<AdminNewInnerProductPage />} />


          <Route path="/admin/banner" element={<AdminBannerPage />} />
          <Route path="/admin/subscriptions" element={<AdminSubscriptionsPage />} />
          <Route path="/admin/videos" element={<AdminVideoPage />} />
          <Route path="/admin/videos/new" element={<AdminNewVideoPage />} />

        </Routes>
      </Router>
      <ScrollTopHelper />
    </>
  );
}

export default App;
