import { Link } from "react-router-dom";

export default function TopAdvertisement() {
  return (
    <div className='p-[24px] flex items-center justify-between'>
        <div className="h-full w-[549px]">
            <p className="text-primary font-semibold text-[34px]">CÔNG TY TNHH KTC VINA</p>
            <p className="font-roboto text-[27px] text-neutral-7 font-light mt-2">Chuyên phân phối <span className="text-secondary font-semibold font-roboto">vòng bi công nghiệp</span> phân khúc ủy quyền chính hãng tại Việt Nam</p>
            <Link to={`/contact`}>
              <button className="bg-primary text-white px-[20px] py-[10px] mt-8 rounded-xl font-roboto font-bold">Liên hệ tư vấn</button>
            </Link>
        </div>
        <img loading="lazy" src={process.env.PUBLIC_URL + '/v2/first-adv.svg'} width={567} height={300} alt='top-adv' />
    </div>
  );
}