import ServicesBreadcrumb from "components/breadcrumb/services-breadcrumb";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArticleService } from "lib/services/article.service";
import ArticleDetail from "lib/models/article-detail";
import { ArticleItem } from "lib/models/article-item";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";
import ServiceTypeInformation from "./(sections)/service-type-information";
import ServiceCarouselBox from "./(sections)/service-carousel-box";
import TopMostBanner from "pages/(landing-page)/top-most/top-most-banner";

export default function ServiceDetailPage() {
    const { id } = useParams<{id: string}>();
    const [articleDetail, setArticleDetail] = useState<ArticleDetail | undefined>(undefined);
    const [mostRelatedServices, setMostRelatedServices] = useState<ArticleItem[]>([]);

    useEffect(() => {
        if(id){
            ArticleService.getArticleUniquePath(id).then(res => {
                setArticleDetail(res);
            })
        }
    }, [id])

    useEffect(() => {
        if(articleDetail){
            ArticleService.getAllServices().then(res => {
                const mostRelatedServicesData = res.filter(x => x.id !== articleDetail.id).slice(0, 4);
                setMostRelatedServices(mostRelatedServicesData);
            })
        }
    }, [articleDetail])

    const typeRoutearticleDetail: BreadcrumbRouteMetadata = {
        title: id ?? '',
        routerLink: '#'
      }
    
      return (
        <>
          {/* Navbar and breadcrumbs */}
          <TopMostBanner />
          <AppNavbar />
    
          <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
              <ServicesBreadcrumb type={typeRoutearticleDetail} />
            </div>
    
            {/* Main section */}
            <main className="container-padding mb-[48px]">
              <h3 className="mt-[10px] mb-[10px] py-2 px-4 text-neutral-8 bg-white rounded-xl">
                {articleDetail?.headerText}
              </h3>
              <ServiceTypeInformation
                description={articleDetail?.contentData ?? ''}
                image={{url: articleDetail?.outerImage.urlPath ?? '', width: 454, height: 240, alt: articleDetail?.headerText ?? ''}}
                videos={articleDetail?.videos}
              />
    
              {/* Book services */}
              <ServiceCarouselBox
                className="mt-[32px]"
                title = { 'Một số dịch vụ khác' }
                items = { mostRelatedServices }
                shortDescription = { `` }
                routerLink = "/services"
              ></ServiceCarouselBox>
            </main>
    
            <AppFooter />
          </MainContentLayout>
        </>
      )
}