import axios from "axios";
import { ApiEndpoints } from "./api-endpoints";
import FileService from "./file-service";
import SimpleAuthService from "./simple-check-auth.service";
import VideoItem from "lib/models/video";
import VideoDto from "lib/models/video-dto";

export class VideoService {
    public static async getAll() : Promise<VideoItem[]> {
        const response = await axios.get<VideoItem[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.video}`);
        return response.data ? response.data.map(x => {
            x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
            return x;
        }) : [];
    }

    public static async post(video: VideoDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.video}`, video);
    }

    public static async delete(id: string) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.delete(`${ApiEndpoints.backendHost}${ApiEndpoints.video}/${id}`);
    }
}