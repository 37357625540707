import AppNavbar from "./(landing-page)/navbar/app-navbar";
import TopMostBanner from "./(landing-page)/top-most/top-most-banner";
import Firstsponsorsection from "./(landing-page)/first-sponsor-section/first-sponsor-section";
import TopAdvertisement from "./(landing-page)/top-adv";
import ProductsAndServicesSection from "./(landing-page)/products-and-services";
import AboutUs from "./(landing-page)/about-us";
import NewsSection from "./(landing-page)/news";
import OutstandingProducts from "./(landing-page)/outstanding-products";
import AppFooter from "./(landing-page)/footer/app-footer";
import ContactFixed from "./(landing-page)/contact-fixed";
import { ImageCarousel } from "components/carousel/image-carousel";
import { useEffect, useState } from "react";
import { BannerService } from "lib/services/banner-service";
import { ImageMetadata } from "lib/types/image-meta";

export default function LandingPage() {
  const [banners, setBanners] = useState<ImageMetadata[]>([]);

  useEffect(() => {
    BannerService.getAll().then(res => setBanners(res.map(x => ({
      url: x.image.urlPath,
      height: 400,
      width: 1440,
      alt: "banner image"
    }))));
  }, [])

  return (
    <main className="bg-white">
      <TopMostBanner />
      <AppNavbar />
      <ImageCarousel images={banners} />
      <div className='container-padding'>
        <Firstsponsorsection />
        <TopAdvertisement />
      </div>
      <ProductsAndServicesSection />
      <AboutUs />
      <NewsSection />
      <OutstandingProducts />
      <footer>
        <AppFooter />
      </footer>
    </main>
  );
}