import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import ProductTechnicalInfo from "lib/types/product-technical-info";

export default function ProductDetailTabsView(props: {
  detailDescription: string,
  videos?: string,
  technicalInfo: ProductTechnicalInfo
}) {
  return (
    <div className="mt-[10px]">
      <Tabs defaultValue="first-tab">
        <TabsList className="my-[10px]">
          <TabsTrigger value="first-tab">Thông tin chi tiết</TabsTrigger>
          {/* <TabsTrigger value="second-tab">Thông số kỹ thuật</TabsTrigger> */}
        </TabsList>
        <TabsContent value="first-tab">
          <div className="py-[28px] px-[32px] text-body whitespace-pre-wrap bg-white rounded-xl">
            <div className="font-roboto" dangerouslySetInnerHTML={{ __html: props.detailDescription }} />
            <div className="mt-[20px]">
              {props.videos?.split(",").map(vid => (
                vid ?
                <iframe
                  width="100%"
                  height="600px"
                  src={`https://www.youtube.com/embed/${vid}`}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> : <></>
              ))}
            </div>
          </div>
        </TabsContent>
        <TabsContent value="second-tab">
          {/* product properties */}
          <div className="w-full py-[28px] px-[32px] bg-white rounded-xl flex flex-col gap-2 body-text-2">
            {/* Danh muc */}
            <p>
              <span className="font-bold text-neutral-8">Danh mục:&nbsp;</span>
              <span className="text-neutral-7">{props.technicalInfo.type}</span>
            </p>
            {/* Thuong hieu */}
            <p>
              <span className="font-bold text-neutral-8">Thương hiệu:&nbsp;</span>
              <span className="text-neutral-7">{props.technicalInfo.brand}</span>
            </p>
            {/* Ma san pham */}
            <p>
              <span className="font-bold text-neutral-8">Mã sản phẩm:&nbsp;</span>
              <span className="text-neutral-7">{props.technicalInfo.code}</span>
            </p>
            {/* Xuat Xu */}
            <p>
              <span className="font-bold text-neutral-8">Xuất xứ:&nbsp;</span>
              <span className="text-neutral-7">{props.technicalInfo.from}</span>
            </p>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}