import { useEffect, useRef, useState } from "react";
import AdminNavbar from "../navbar";
import BannerImage from "lib/models/banner-images";
import { BannerService } from "lib/services/banner-service";
import AdminBannerItem from "./AdminBannerItem";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import LoadingIndicator from "components/loading";
import FileService from "lib/services/file-service";

export default function AdminBannerPage() {
    const [banners, setBanners] = useState<BannerImage[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const outerImageInput = useRef(null);
    const handleOuterImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files[0]) {
        setIsLoading(true)
        const fileResponse = await FileService.StoreRawFileWithoutZip(files[0]);
        setIsLoading(false)

        BannerService.post(fileResponse.id).then(_ => window.location.reload());
      }
    };


    useEffect(() => {
        BannerService.getAll().then(res => {
            setBanners(res)
        })
    }, [])

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <h1>Hình ảnh banner</h1>
                    {isLoading ? (<LoadingIndicator />) : (<></>)}
                </div>

                <div>
                    {/* OuterImage input */}
                    <label htmlFor="outerImageFile" className={CommonUsingTailwind.labelClass}>Tải lên thêm hình ảnh</label>
                    <input id="outerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={outerImageInput} onChange={handleOuterImageInputChange} />
                </div>
                
                {banners.map(banner => (
                    <AdminBannerItem key={banner.id} banner={banner} />
                ))}
            </div>
        </main>
    );
}