"use client"
 
import * as React from "react"
 
import { cn } from "common/utils/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "components/ui/navigation-menu"
import Configuration from "lib/models/configuration";
import { ConfigurationService } from "lib/services/configuration.service";
import ReferenceData from "common/reference-data";
import { Link } from "react-router-dom";

export interface NavigateMetadata {
  title: string;
  href: string;
  description?: string;
  subNavigate?: NavigateMetadata[]
}

 
export function IntegratedNavBar(props: {configuration: Configuration[]}) {
  const components: NavigateMetadata[] = [
    {
      title: 'VỀ CHÚNG TÔI',
      href: '/about-us'
    },
    {
      title: 'SẢN PHẨM',
      href: '/products',
      subNavigate: [
        {
          title: 'Gối đỡ vòng bi',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_1) ?? '#'
        },
        {
          title: 'Vòng bi FAG',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_2) ?? '#'
        },
        {
          title: 'Vòng bi INA',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_3) ?? '#'
        },
        {
          title: 'Vòng bi NMB',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_4) ?? '#'
        },
        {
          title: 'Các loại vòng bi khác',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_5) ?? '#'
        },
        {
          title: 'Dầu mỡ bôi trơn Bechem',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_6) ?? '#'
        },
        {
          title: 'Các sản phẩm công nghệ mới của Schaeffler',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_SP_7) ?? '#'
        },
      ]
    },
    {
      title: 'DỊCH VỤ',
      href: '/services',
      subNavigate: [
        {
          title: 'Đo kiểm rung động',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_DV_1) ?? '#'
        },
        {
          title: 'Cân chỉnh đồng tâm trục',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_DV_2) ?? '#'
        },
        {
          title: 'Dịch vụ thăm khám và sửa chữa hộp số',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_DV_3) ?? '#'
        },
        {
          title: 'Gia nhiệt và lắp đặt vòng bi',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_DV_4) ?? '#'
        },
      ]
    },
    {
      title: 'CÔNG CỤ BẢO DƯỠNG',
      href: '/products-maintain',
      subNavigate: [
        {
          title: 'Bộ gia nhiệt',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_CCBD_1) ?? '#'
        },
        {
          title: 'Cảo thủy lực',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_CCBD_2) ?? '#'
        },
        {
          title: 'Các giải pháp bôi trơn',
          href: ConfigurationService.GetConfigurationValue(props.configuration, ReferenceData.NAV_CCBD_3) ?? '#'
        }
      ]
    },
    {
      title: 'HỖ TRỢ KĨ THUẬT',
      href: '/blogs?type=support'
    },
    {
      title: 'TIN TỨC',
      href: '/blogs?type=news'
    }
  ]

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {components.map(navigateData => (
          <NavigationMenuItem key={navigateData.title}>
            {navigateData.subNavigate ? (
              <>
                <NavigationMenuTrigger className="bg-inherit hover:text-primar button-text" navLink={navigateData.href}>{navigateData.title}</NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="gap-3 overflow-hidden w-[500px] rounded-xl bg-white border-[1px] rounded-b-[4px] rounded-t-[0px] border-neutral-3">
                    {navigateData.subNavigate.map((subNavigateData, index) => (
                      <ListItem
                        className={`${index === navigateData.subNavigate!.length - 1 ? '!border-b-[0px]' : ''}`}
                        href={subNavigateData.href}
                        title={subNavigateData.title}
                        key={subNavigateData.title}
                      >
                        {subNavigateData.description}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </>
            ) : (
              <Link to={navigateData.href}>
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  {navigateData.title}
                </NavigationMenuLink>
              </Link>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
 
const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none py-3 text-primary hover:text-secondary border-b-[1px] border-neutral-2 px-4 leading-none no-underline outline-none transition-colors",
            className
          )}
          {...props}
        >
          <div className="button-text-s">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})

ListItem.displayName = 'ListItem';