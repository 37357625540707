import VideoDto from "lib/models/video-dto";
import FileService from "lib/services/file-service";
import { VideoService } from "lib/services/video-service";
import { FormEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../navbar";
import LoadingIndicator from "components/loading";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import Required from "components/required-field";

export default function AdminNewVideoPage(){
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
  
    const [video, setVideo] = useState<VideoDto>({
        imageId: 0,
        headerText: '',
        uniqueYoutubeId: ''
    });

    const [outerImageSrc, setOuterImageSrc] = useState<string>('');
  
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      // post article
      await VideoService.post(video);
  
      navigate('/admin/videos');
      window.location.reload();
    }
  
    const outerImageInput = useRef(null);
    const handleOuterImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files[0]) {
        setIsLoading(true)
        const fileResponse = await FileService.StoreFile(files[0]);
        setIsLoading(false)
        setOuterImageSrc(fileResponse.outputPath);
        video.imageId = fileResponse.id;
      }
    };

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <h1>Tạo mới video</h1>
                    {isLoading ? (<LoadingIndicator />) : (<></>)}
                </div>
            </div>

            {/* Form */}
            <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmit}>
                {/* Name input */}
                <div>
                    <label htmlFor="headerText" className={CommonUsingTailwind.labelClass}>Tên video <Required /></label>
                    <input
                        id="headerText"
                        className={CommonUsingTailwind.inputClass}
                        type="text"
                        value={video.headerText}
                        onChange={e => { video.headerText = e.target.value; setVideo({ ...video }) }}
                        placeholder="Tên sản phẩm"
                    />
                </div>

                {/* Name input */}
                <div>
                    <label htmlFor="namePath" className={CommonUsingTailwind.labelClass}>Video ID: <Required /></label>
                    <input
                        id="namePath"
                        className={CommonUsingTailwind.inputClass}
                        type="text"
                        value={video.uniqueYoutubeId}
                        onChange={e => { video.uniqueYoutubeId = e.target.value; setVideo({ ...video }) }}
                        placeholder="Đường dẫn"
                    />
                </div>
                
                <div>
                    {/* OuterImage input */}
                    <label htmlFor="outerImageFile" className={CommonUsingTailwind.labelClass}>Hình ảnh <Required /></label>
                    <input id="outerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={outerImageInput} onChange={handleOuterImageInputChange} />
                    {outerImageSrc ? <img loading="lazy" width={100} height={100} src={outerImageSrc} alt='outerImage' /> : <></>}
                </div>

                <button className={isLoading ? CommonUsingTailwind.sumbitButtonDisabled : CommonUsingTailwind.sumbitButton} type="submit" disabled={isLoading}>sumbit</button>
                </form>
        </main>
    );
}