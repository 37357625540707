import Cookies from 'js-cookie';

export default class SimpleAuthService {
    public static readonly username = "admin";
    public static readonly password = "2024@KTCVina@2024";

    public static setCookiePassword(val: string) {
        Cookies.set('userPassword', val, { expires: 3 / 24 }); // 3/24 is 3 hours
    };

    public static setCookieUsername(val: string) {
        Cookies.set('username', val, { expires: 3 / 24 }); // 3/24 is 3 hours
    };

    public static CheckAuth(usernameInput: string, passwordInput: string): boolean {
        if(usernameInput === this.username && passwordInput === this.password){
            return true;
        }
        return false;
    }

    public static Check(): boolean {
        const curPassword = Cookies.get('userPassword');
        const curUsername = Cookies.get('username');

        if(!curPassword || !curUsername || curUsername !== this.username || curPassword !== this.password){
            return false;
        }

        return true;
    }
}