import { useAppContext } from "context/ConfigurationContext";
import { ConfigurationService } from "lib/services/configuration.service";

export default function ContactFixed() {
    const {configurations} = useAppContext();

    function openZalo() {
        const zaloLink = `https://zalo.me/${ConfigurationService.GetConfigurationValue(configurations, 'Zalo')}`;
        window.open(zaloLink, '_blank')?.focus();
    }

    return (
        <div className="fixed top-[92vh] right-[20px] !z-[1000]" onClick={openZalo}>
            <button className="p-[12px] bg-secondary text-white rounded-2xl flex items-center gap-3">
                <img loading="lazy" width={24} height={25} src={`/v2/contact-icon.svg`} alt="contact-icon"></img>
                <p className="text-[16px] font-roboto font-semibold">{`${ConfigurationService.GetConfigurationValue(configurations, 'Zalo')} (Ms. Nhung)`}</p>
            </button>
        </div>
    )
}