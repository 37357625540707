import { GenericServiceItem } from "lib/types/generic-service-item";
import ProductCarouselBox from "../../../components/carousel/product-carousel/product-carousel-box"
import { OuterProductService } from "lib/services/outer-product.service";
import { useEffect, useState } from "react";
import OuterProduct from "lib/models/outer-product";
import { ProductType } from "lib/types/product-type";

export interface ProductTypeMetadata {
  title: string;
  routerLink: string;
  shortDescription: string;
  items: GenericServiceItem[];
}

export default function AllProductsSection(props: {productType: ProductType}){
  const [products, setProducts] = useState<OuterProduct[]>([]);

  useEffect(() => {
    if(props.productType === ProductType.product){
      OuterProductService.getAllOuterProduct().then(res => {
        setProducts(res)
      })
    } else {
      OuterProductService.getAllOuterProductMaintain().then(res => {
        setProducts(res)
      })
    }
  }, [props.productType])

  return <div className="mt-[10px]">
  {products.map(metadata => (
    <ProductCarouselBox
      title = { metadata.name }
      items = { metadata.innerProducts }
      routerLink = { `/products/${metadata.namePath}` }
      shortDescription = { metadata.summaryDetail }
      key = { metadata.id }
    ></ProductCarouselBox>
  ))}
  </div>
}