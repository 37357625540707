import { ArticleItem } from "lib/models/article-item";
import { ArticleService } from "lib/services/article.service";
import { ArticleType } from "lib/types/article-type";
import { ImageMetadata } from "lib/types/image-meta";
import ServicesByTypeBox from "pages/services/(sections)/services-by-type-box";
import { useEffect, useState } from "react";

export interface ServiceTypeMetadata {
  id: string,
  routerLink: string,
  header: string;
  description: string;
  image: ImageMetadata
}

export default function AllPostsSection(props: {type?: ArticleType}){
  const [articles, setArticles] = useState<ArticleItem[]>([]);

  useEffect(() => {
    if(props.type === ArticleType.support){
      ArticleService.getAllSupportArticles().then(res => {
        setArticles(res)
      })
    } else {
      ArticleService.getAllPosts().then(res => {
        setArticles(res)
      })
    }
  }, [props.type])

  return <div>
  {articles.map(metadata => (
    <ServicesByTypeBox
      header = { metadata.headerText }
      description = { metadata.summaryText }
      image = {{
        url: metadata.outerImagePath,
        alt: metadata.headerText,
        width: 360,
        height: 200
      }}
      routerLink = { `/blogs/${metadata.articlePath}` }
      key = { metadata.articlePath }
    ></ServicesByTypeBox>
  ))}
  </div>
}