import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "components/ui/breadcrumb";
import HomeBreadcrumbItem from "./home-breadcrumb-item";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";

export default function ProductBreadcrumb(props: {
  type?: BreadcrumbRouteMetadata,
  detail?: BreadcrumbRouteMetadata
}) {
  return (
    <Breadcrumb className="container-padding">
      <BreadcrumbList>
        <HomeBreadcrumbItem />
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          {
            props.type 
            ? (<BreadcrumbLink href="/products">Sản phẩm</BreadcrumbLink>) 
            : (<BreadcrumbPage>Sản phẩm</BreadcrumbPage>)
          }
        </BreadcrumbItem>
        {props.type ? (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {
                props.detail 
                ? (<BreadcrumbLink href={props.type!.routerLink} >{props.type!.title}</BreadcrumbLink>)
                : (<BreadcrumbPage>{props.type!.title}</BreadcrumbPage>)
              }
            </BreadcrumbItem>
          </>
        ) : (<></>)}
        {props.detail ? (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{props.detail!.title}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        ) : (<></>)}
      </BreadcrumbList>
    </Breadcrumb>
  )
}