import NavItem from "./navItem";

export default function AdminNavbar(){
    return (
        <div className="w-full mx-auto bg-white flex justify-between pr-4">
            <div className="flex">
                <NavItem href="/admin/about-us" label="Về chúng tôi" />
                <NavItem href="/admin/all-products" label="Sản phẩm" />
                <NavItem href="/admin/services" label="Dịch vụ" />
                <NavItem href="/admin/all-maintainproducts" label="Công cụ bảo dưỡng" />
                <NavItem href="/admin/supports" label="Hỗ trợ kĩ thuật" />
                <NavItem href="/admin/news" label="Tin tức" />
                {/* <NavItem href="/admin/articles" label="Bài viết" /> */}
                <NavItem href="/admin/subscriptions" label="Email đã đăng kí" />
                <NavItem href="/admin/banner" label="Hình ảnh banner" />
                <NavItem href="/admin/configuration" label="Cấu hình" />
                <NavItem href="/admin/videos" label="Videos" />
            </div>
            <img loading="lazy" width={169} height={44} src={`/v2/ktc-nav-logo.svg`} alt="nav-logo" />
        </div>
    )
}