import OuterProductDto from 'lib/models/outer-product.dto';
import FileService from 'lib/services/file-service';
import { OuterProductService } from 'lib/services/outer-product.service';
import { FormEvent, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminNavbar from '../navbar';
import CommonUsingTailwind from 'lib/services/common-using-tailwind';
import Required from 'components/required-field';
import LoadingIndicator from 'components/loading';
import { ProductType } from 'lib/types/product-type';


export default function AdminNewOuterProductPage() {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const defaultValue = queryParams.get('default');

    const [isLoading, setIsLoading] = useState(false);
  
    const [outerProduct, setOuterProduct] = useState<OuterProductDto>({
        name: '',
        namePath: '',
        summaryDetail: '',
        productType: defaultValue ?? '0',
        imageId: 0
    });

    const [outerImageSrc, setOuterImageSrc] = useState<string>('');
  
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      // post article
      await OuterProductService.postOuterProduct(outerProduct);
  
      navigate('/admin/products');
      window.location.reload();
    }
  
    const outerImageInput = useRef(null);
    const handleOuterImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files[0]) {
        setIsLoading(true)
        const fileResponse = await FileService.StoreFile(files[0]);
        setIsLoading(false)
        setOuterImageSrc(fileResponse.outputPath);
        outerProduct.imageId = fileResponse.id;
      }
    };

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                    <h1>Tạo mới sản phẩm</h1>
                    {isLoading ? (<LoadingIndicator />) : (<></>)}
                </div>
            </div>

            {/* Form */}
            <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmit}>
                {/* Name input */}
                <div>
                    <label htmlFor="headerText" className={CommonUsingTailwind.labelClass}>Tên sản phẩm <Required /></label>
                    <input
                        id="headerText"
                        className={CommonUsingTailwind.inputClass}
                        type="text"
                        value={outerProduct.name}
                        onChange={e => { outerProduct.name = e.target.value; setOuterProduct({ ...outerProduct }) }}
                        placeholder="Tên sản phẩm"
                    />
                </div>

                {/* Name input */}
                <div>
                    <label htmlFor="namePath" className={CommonUsingTailwind.labelClass}>Đường dẫn (duy nhất) <Required /></label>
                    <input
                        id="namePath"
                        className={CommonUsingTailwind.inputClass}
                        type="text"
                        value={outerProduct.namePath}
                        onChange={e => { outerProduct.namePath = e.target.value; setOuterProduct({ ...outerProduct }) }}
                        placeholder="Đường dẫn"
                    />
                </div>

                {/* Text input */}
                <div>
                    <label htmlFor="summaryDetail" className={CommonUsingTailwind.labelClass}>Tóm tắt sản phẩm <Required /></label>
                    <textarea
                        id="summaryDetail"
                        className={CommonUsingTailwind.inputClass}
                        value={outerProduct.summaryDetail}
                        onChange={e => { outerProduct.summaryDetail = e.target.value; setOuterProduct({ ...outerProduct }) }}
                        placeholder="Tóm tắt"
                    />
                </div>

                {defaultValue ? (<></>) : (
                    <div>
                        {/* ArticleType input */}
                        <label htmlFor="type" className={CommonUsingTailwind.labelClass}>Loại <Required /></label>
                        <select
                            id="type"
                            className={CommonUsingTailwind.inputOptionClass}
                            onChange={e => {
                                const type = e.target.value;
                                outerProduct.productType = type;
                                setOuterProduct({ ...outerProduct });
                            }}
                        >
                            <option value={ProductType.product} selected>Sản phẩm</option>
                            <option value={ProductType.maintain}>Công cụ bảo dưỡng</option>
                        </select>
                    </div>
                )}
                
                <div>
                    {/* OuterImage input */}
                    <label htmlFor="outerImageFile" className={CommonUsingTailwind.labelClass}>Hình ảnh <Required /></label>
                    <input id="outerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={outerImageInput} onChange={handleOuterImageInputChange} />
                    {outerImageSrc ? <img loading="lazy" width={100} height={100} src={outerImageSrc} alt='outerImage' /> : <></>}
                </div>

                <button className={isLoading ? CommonUsingTailwind.sumbitButtonDisabled : CommonUsingTailwind.sumbitButton} type="submit" disabled={isLoading}>sumbit</button>
                </form>
        </main>
    )
}