import { ImageMetadata } from "lib/types/image-meta"
import AppImage from "../image-container"
import { Link } from "react-router-dom"

export default function ProductItemCard(props: {
    image: ImageMetadata,
    name: string,
    detailUrl: string
}){
    return (
        <div className="bg-white p-[12px] border-[1px] border-neutral-3 rounded-[8px] flex flex-col justify-between hover:cursor-pointer hover:border-primary">
            <div className="w-full">
                <AppImage 
                    image={props.image}
                />
                <div className="w-full text-center mt-[12px] line-clamp-1">{props.name}</div>
            </div>
            <div className="flex justify-center w-full mt-[12px]">
                <Link to={props.detailUrl} className="bg-primary text-white px-[24px] py-[8px] rounded-xl">XEM CHI TIẾT</Link>
            </div>
        </div>
    )
}