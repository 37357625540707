import ServicesBreadcrumb from "components/breadcrumb/services-breadcrumb";
import GenericTopSection from "pages/(common)/generic-top-section";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import AllServicesSection from "./(sections)/all-services-section";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import TopMostBanner from "pages/(landing-page)/top-most/top-most-banner";

export default function AllServicesPage() {
    return (
        <>
          <TopMostBanner />
          <AppNavbar />
          <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
              <ServicesBreadcrumb />
            </div>
    
            {/* Main section */}
            <main className="container-padding mt-[10px] !mb-[20px]">
              <GenericTopSection
                sectionHeader="Tất cả dịch vụ"
                image={
                  {
                    url: "/v2/all-services-banner.svg",
                    width: 1204,
                    height: 240
                  }
                }  
              />
              <AllServicesSection />
            </main>
            
            <AppFooter />
          </MainContentLayout>
    
        </>
      )
}