import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import MainContentLayout from "./(common)/main-content-layout";
import AppNavbar from "./(landing-page)/navbar/app-navbar";
import GenericTopSection from "./(common)/generic-top-section";
import AppFooter from "./(landing-page)/footer/app-footer";
import TopMostBanner from "./(landing-page)/top-most/top-most-banner";
import { useAppContext } from "context/ConfigurationContext";
import { ConfigurationService } from "lib/services/configuration.service";

export default function AboutUsPage() {
    const {configurations} = useAppContext();

    return (
        <>
        <TopMostBanner />
        <AppNavbar />
        <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
            <OneLevelBreadcrumb label="Về chúng tôi" />
            </div>

            {/* Main section */}
            <main className="container-padding my-[10px]">
                <GenericTopSection
                    sectionHeader="Về chúng tôi"
                    image={
                    {
                        url: "/v2/all-members-new.png",
                        width: 1204,
                        height: 400
                    }
                    }  
                />
                <div className="p-[20px] bg-white rounded-xl my-[20px] whitespace-pre-wrap">
                    <div className="font-roboto text-neutral-7" dangerouslySetInnerHTML={{ __html: ConfigurationService.GetConfigurationValue(configurations, 'ABOUT_US') ?? ''}} />
                </div>
            </main>

            <AppFooter />
        </MainContentLayout>
        </>
    )
}