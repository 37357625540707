import axios from "axios";
import Subscription from "lib/models/subscription";
import { ApiEndpoints } from "./api-endpoints";

export class SubscriptionService {
    public static async getAll() : Promise<Subscription[]> {
        const response = await axios.get<Subscription[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.subscription}`);
        return response.data;
    }

    public static async post(emailValue: string) : Promise<void> {
        await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.subscription}`, {emailValue});
    }
}