import Configuration from 'lib/models/configuration';
import { ConfigurationService } from 'lib/services/configuration.service';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface ConfigurationState {
  configurations: Configuration[]
  setConfigurations: React.Dispatch<React.SetStateAction<Configuration[]>>;
}

const AppContext = createContext<ConfigurationState | undefined>(undefined);

type Props = {
    children?: React.ReactNode
};

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [configurations, setConfigurations] = useState<Configuration[]>([]);

  useEffect(() => {
    ConfigurationService.GetAllConfigurations().then(res => setConfigurations(res))
  }, [])

  return (
    <AppContext.Provider value={{ configurations, setConfigurations }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
