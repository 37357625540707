import ProductBreadcrumb from "components/breadcrumb/products-breadcrumb";
import NotFound from "components/notfound";
import OuterProductDetail from "lib/models/outer-product-detail";
import { OuterProductService } from "lib/services/outer-product.service";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";
import GenericTopSection from "pages/(common)/generic-top-section";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductTypeInformation from "./(sections)/product-type-information";
import TypeProducts from "./(sections)/type-products";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import { GenericServiceItem } from "lib/types/generic-service-item";
import ProductCarouselBox from "components/carousel/product-carousel/product-carousel-box";
import TopMostBanner from "pages/(landing-page)/top-most/top-most-banner";

export interface ProductTypeDetailMetadata {
    id: string;
    type: string;
    description: string;
    products: GenericServiceItem[][]; // 2 row
    news: GenericServiceItem[];
  }

export default function ProductTypePage() {
    const {type} = useParams<{type: string}>();
    const [detail, setDetail] = useState<OuterProductDetail | undefined>(undefined);

    const typeRouteMetadata: BreadcrumbRouteMetadata = {
        title: type ?? '',
        routerLink: '#'
      }

    useEffect(() => {
        if(type){
            OuterProductService.getOuterProductByPath(type).then(res => {
                setDetail(res)
            })
            
        }
    }, [type])
    
      if(!detail){
        return NotFound();
      }
    
      return (
        <>
          {/* Navbar and breadcrumbs */}
          <TopMostBanner />
          <AppNavbar />
          <MainContentLayout>
            {/* Breadcrumb */}
            <div className="mt-0.5 py-1 rounded-xl">
              <ProductBreadcrumb type={typeRouteMetadata} />
            </div>
    
            {/* Main section */}
            <main className="container-padding mt-[10px] !mb-[20px]">
              <GenericTopSection
                sectionHeader={detail.name}
                image={
                  {
                    url: detail.image.urlPath,
                    width: 1204,
                    height: 240
                  }
                }
              />
              <ProductTypeInformation type={detail.name} description={detail.summaryDetail} />

              {detail.innerProducts.length > 4 ? (
                <TypeProducts title={detail.name} pathType={detail.namePath} products={detail.innerProducts} />
              ) : (
                <div className="mt-[10px]">
                  <ProductCarouselBox
                    title = {`Sản phẩm - ${detail.name}`}
                    items = { detail.innerProducts }
                    routerLink = { `/products/${detail.namePath}` }
                    shortDescription = {''}
                    key = { detail.id }
                  ></ProductCarouselBox>
                </div>
              )}
              {/* <ProductsByTypeNews title={metadata.type} news={metadata.news} /> */}
            </main>
    
            <AppFooter />
          </MainContentLayout>
        </>
      )
}