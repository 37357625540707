import ProductBreadcrumb from "components/breadcrumb/products-breadcrumb";
import GenericTopSection from "pages/(common)/generic-top-section";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import AllProductsSection from "./(sections)/all-products-section";
import TopMostBanner from "pages/(landing-page)/top-most/top-most-banner";
import { ProductType } from "lib/types/product-type";

export default function AllProductPage() {
    return (
        <>
          <TopMostBanner />
          <AppNavbar />
          <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
              <ProductBreadcrumb />
            </div>
    
            {/* Main section */}
            <main className="container-padding mt-[10px] !mb-[20px]">
              <GenericTopSection
                sectionHeader="Tất cả sản phẩm"
                image={
                  {
                    url: "/v2/all-product-banner.svg",
                    width: 1204,
                    height: 240
                  }
                }  
              />
              <AllProductsSection productType={ProductType.product} />
            </main>
    
            <AppFooter />
          </MainContentLayout>
        </>
      )
}