import SearchBreadcrumb from "components/breadcrumb/search-breadcrumb"
import MainContentLayout from "./(common)/main-content-layout"
import AppNavbar from "./(landing-page)/navbar/app-navbar"
import GenericTopSection from "./(common)/generic-top-section"
import ProductItemCard from "components/product-item-card"
import AppFooter from "./(landing-page)/footer/app-footer"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import InnerProduct from "lib/models/inner-product"
import { OuterProductService } from "lib/services/outer-product.service"
import TopMostBanner from "./(landing-page)/top-most/top-most-banner"

export default function SearchPage() {
    const { name } = useParams<{ name: string }>();

    const [innerProducts, setInnerProducts] = useState<InnerProduct[]>([]);

    useEffect(() => {
        OuterProductService.searchInnerProduct(name ?? '').then(res => {
            setInnerProducts(res);
        })
    }, [name])


    return (
        <>
        <TopMostBanner />
        <AppNavbar />
        <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
            <SearchBreadcrumb />
            </div>

            {/* Main section */}
            <main className="container-padding my-[10px]">
                <GenericTopSection sectionHeader="Tìm kiếm" />
                <div className="p-[20px] bg-white rounded-xl mt-[20px] whitespace-pre-wrap">
                    Tìm kiếm theo tên: {name}
                </div>

                <div className="p-[20px] bg-white rounded-xl my-[20px] min-h-[70vh] ">
                <div className="px-[24px] outstanding-grid-container">
                    {innerProducts.map(item => {
                        return (
                            <ProductItemCard 
                                key={item.name}
                                image={{
                                    url:item.image.urlPath,
                                    width: 199,
                                    height: 160,
                                    alt: item.name
                                }}
                                name={item.name}
                                detailUrl={`/products/${item.outerProductName}/${item.namePath}`}
                            />
                        )
                    })}
                </div>
                </div>
            </main>

            <AppFooter />
        </MainContentLayout>
        </>
    )
}